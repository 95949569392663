.tou {
    text-align: left;

    h4, h3 {
        margin-bottom: 0px;
    }

    div {
        font-size: inherit;
        line-height: 1.6;
        margin-bottom: 1rem;
        text-align: left;
    }
}
