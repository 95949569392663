
$paddingLarge: 20px;
$paddingLarger: 18px;
$paddingMedium: 15px;
$paddingSmaller: 12px;
$paddingSmall: 8px;

$marginLargest: 24px;
$marginLarge: 21px;
$marginLarger: 18px;
$marginMedium: 15px;
$marginSmaller: 11px;
$marginSmall: 8px;
$marginSmallest: 5px;

$borderRadiusMedium: 4px;
$borderRadiusSmall: 3px;
