
.uiCheckbox {
	input {
		position: absolute;
		left: -10000px;
	}

	label {
		@include box-sizing(border-box);
		position: relative;
		display: block;
		padding-left: $formsCheckboxSize + $formsCheckboxMargin;

		&,
		span {
			cursor: pointer;
		}

		&:before {
			content: '';
			@include box-sizing(border-box);
			position: absolute;
			left: 0;
			top: ($subheadArtifactHeight + $subheadBaselineHeight - $formsCheckboxSize) / 2;
			width: $formsCheckboxSize;
			height: $formsCheckboxSize;
			margin-top: 1.7px;
			border: 1px solid $formsElementBorderColor;
			border-radius: 2px;
			background: white;
			cursor: pointer;
		}

		&:after {
			content: 'q';
			position: absolute;
			left: $formsCheckboxCheckHorizontalOffset;
			top: ($subheadArtifactHeight + $subheadBaselineHeight - $formsCheckboxSize) / 2;
			width: $formsCheckboxSize;
			height: $formsCheckboxSize;
			line-height: $formsCheckboxSize;
			font-size: $formsCheckboxCheckFontSize;
			text-align: center;
			color: rgba($gunmetalWeak, 0.5);
			opacity: 0;
			margin-top: 2px;
			margin-left: -1px;
			@include transition(all $transitionLengthShort);
			@include glyph();
		}

		&:hover {
			&:before {
				border-color: $formsElementBorderColorHover;
			}

			&:after {
				opacity: 1;
			}
		}
	}

	input:checked + label {
		&:after {
			color: $blue;
			opacity: 1;
		}
	}

	input:focus + label {
		&:before {
			border-color: $blue;
		}
	}

	input[disabled] + label {
		pointer-events: none;
		cursor: default;
		color: $weakest;

		&:before {
			border-color: $formsElementBorderColorDisabled;
		}
	}

	input[disabled]:checked + label {
		&:after {
			color: $formsElementColorDisabled;
		}
	}
}