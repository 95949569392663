// Form element variables
$formsElementHeight: 30px;
$formsElementPaddingHorizontal: 10px;
$formsElementPaddingTop: 1px;

$formsElementFontSize: $bodyFontSize;
$formsElementFontWeight: $bodyFontWeight;
$formsElementLetterSpacing: $tightLetterSpacing;
$formsElementLineHeight: $bodyLineHeight;

$formsElementItemPadding: 10px;
$formsElementItemBackgroundHover: rgba($gunmetalWeak, 0.1);
$formsElementItemBackgroundActive: rgba($gunmetalWeak, 0.2);

$formsElementBorderWidth: 1px;
$formsElementBorderColor: $gunmetalWeak;
$formsElementBorderColorHover: $gunmetalStrong;
$formsElementBorderColorActive: $blue;
$formsElementBorderColorInvalid: $red;
$formsElementBorderColorInvalidHover: $red1;
$formsElementBorderColorDisabled: rgba($formsElementBorderColor, 0.5);
$formsElementBorderRadius: $borderRadiusSmall;
$formsElementColor: $strong;
$formsElementColorDisabled: rgba($formsElementBorderColor, 0.5);
$formsElementVerticalAlign: bottom;

// Form label variables
$formsLabelHeight: 14px;
$formsLabelColor: $gunmetalWeak;
$formsLabelColorHover: $gunmetalNeutral;
$formsLabelColorActive: $blue;
$formsLabelColorInvalid: $red;

$formsPlaceholderColor: $veryWeak;

// Form checkbox variables
$formsCheckboxSize: 18px;
$formsCheckboxMargin: 12px;
$formsCheckboxCheckFontSize: 11pt;
$formsCheckboxCheckHorizontalOffset: 0.06em;
$formsCheckboxSpacing: 12px + $subheadArtifactHeight;

// Form radio variables
$formsRadioSize: 18px;
$formsRadioMargin: 12px;
$formsRadioDotSize: 8px;
$formsRadioDotScaleHover: 0.8;

// Form button variables
$formsButtonFontFamily: "TeXGyreHerosRegular";

// Medium size button parameters
$formsButtonHeightMedium: 38px;
$formsButtonPaddingMedium: 17px;
$formsButtonFontSizeMedium: 12px;
$formsButtonFontWeightMedium: 500;
$formsButtonLetterSpacingMedium: 60;

// Large size button parameters
$formsButtonHeightLarge: 52px;
$formsButtonPaddingLarge: 25px;
$formsButtonFontSizeLarge: 20px;
$formsButtonFontWeightLarge: 500;
$formsButtonLetterSpacingLarge: 60;

// Inline size button parameters
$formsButtonHeightInline: $formsElementHeight;
$formsButtonPaddingInline: 13px;
$formsButtonFontSizeInline: $formsButtonFontSizeLarge;
$formsButtonFontWeightInline: $formsButtonFontWeightLarge;
$formsButtonLetterSpacingInline: $formsButtonLetterSpacingLarge;

// Tag variables
$formsTagHeight: 20px;
$formsTagPadding: 6px;
$formsTagMargin: 5px;
$formsTagBackground: #ededed;
$formsTagRemovePadding: $formsTagPadding;
$formsTagRemoveWidth: 5px;
$formsTagRemoveMargin: $marginSmall;

// Form transition variables
$formsTransitionLength: 200ms;
