// Float labels
.uiFloatLabel {
	.placeholder {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: $formsElementHeight;
		line-height: $formsElementHeight;
		padding: ($formsLabelHeight + $formsElementPaddingTop) ($formsElementPaddingHorizontal + $formsElementBorderWidth) 0;
		margin-top: 0;
		margin-bottom: 0;
		color: $formsPlaceholderColor;
		z-index: 3;
		cursor: pointer;
		pointer-events: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	label {
		position: absolute;
		top: 1px;
		color: $formsLabelColor;
		z-index: 1;

		opacity: 0;
		@include transform(translateY(2px));
		@include transition(all $formsTransitionLength);

		.label {
			position: absolute;
			top: 0;
			left: 0;
			white-space: nowrap;
			@include transition(opacity $transitionLengthShort);
		}
	}

	.hover & {
		label {
			color: $formsLabelColorHover;
		}
	}

	.focus & {
		label {
			color: $formsLabelColorActive;
		}
	}

	// In focus and invalid states, and when the element
	// has a value entered, or when the flasher attribute 
	// is used, show the top label
	.focus &,
	.hasValue &,
	.invalid &,
	.flasher & {
		label {
			opacity: 1;
			@include transform(translateY(0));
		}
	}

	// When the element has a value entered, hide the placeholder
	.hasValue & {
		.placeholder {
			opacity: 0;
		}
	}

	// Show error messages in place of element label when
	// validation fails
	.invalid & {
		label {
			color: $red;
		}
	}

	// Realign placeholder with element when noLabel
	// (no filled state) is added
	.noLabel & {
		.placeholder {
			padding-top: $bodyArtifactHeight/2;
		}
	}
}