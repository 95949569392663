// Select input

$formsSelectPaddingTop: 1px;
$formsSelectDividerInset: $formsElementPaddingHorizontal;
$formsSelectLoaderSize: 20px;
$formsSelectDropdownMetaPaddingVertical: 5px;
$formsSelectDropdownMetaFontSize: 20px;
$formsSelectDropdownMetaHeight: $formsSelectDropdownMetaFontSize + 2*$formsSelectDropdownMetaPaddingVertical;
$formsSelectDropdownMinHeight: $formsSelectDropdownMetaHeight;
$formsSelectDropdownMaxHeight: 200px;
$formsSelectTagMargin: 4px;

.uiSelect {
	position: relative;
	padding-top: $formsLabelHeight;

	& > .element {
		@include display-flex;
		display: -ms-flexbox;
		@include box-sizing(border-box);
		position: relative;
		width: 100%;
		height: $formsElementHeight;
		line-height: $formsElementHeight;
		padding: $formsSelectPaddingTop ($formsElementPaddingHorizontal + 18px) 0 $formsElementPaddingHorizontal;
		border: $formsElementBorderWidth solid $formsElementBorderColor;
		border-radius: $formsElementBorderRadius;
		font-size: $formsElementFontSize;
		color: $formsElementColor;
		background: $white;
		letter-spacing: $formsElementLetterSpacing;
		cursor: pointer;
		z-index: 2;
		@include transition(border-color $transitionLengthShort);

		.inlineLabel {
			margin-right: 4px;
			line-height: $formsElementHeight - $formsElementBorderWidth;
			white-space: nowrap;

			&.glyph {
				margin-top: -2px;
				margin-right: 10px;
				font-size: 22px;
			}
		}

		.value {
			display: block;
			line-height: $formsElementHeight;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.uiTags {
				margin-top: 0;
				margin-left: -$formsTagMargin + $formsSelectDividerInset - $formsElementPaddingHorizontal;
				padding-bottom: $formsSelectTagMargin;

				.uiTag {
					margin-top: $formsSelectTagMargin;
					margin-left: $formsTagMargin;
				}
			}
		}

		/* Dropdown arrow */
		&:before {
			content: '1';
			position: absolute;
			top: 50%;
			right: $formsElementPaddingHorizontal;
			margin-top: -0.4em;
			line-height: 1;
			font-size: 16px;
			@include glyph();
			color: $formsElementBorderColor;
			@include transition(all $transitionLengthShort);
		}

		&:hover {
			border-color: $formsElementBorderColorHover;

			/* Dropdown arrow */
			&:before {
				color: $formsElementBorderColorHover;
			}
		}
	}

	&.dropdownOpen {
		& > .element {
			border-bottom: none;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			/* Dropdown arrow */
			&:before {
				color: $formsElementBorderColorActive;
				@include transform(rotateZ(-180deg));
			}

			/* Divider border */
			&:after {
				content: '';
				position: absolute;
				left: $formsSelectDividerInset;
				right: $formsSelectDividerInset;
				bottom: 1px;
				height: 1px;
				background: $borderWeak;
			}
		}
	}

	&.focus {
		& > .element {
			/* Dropdown arrow */
			&:before {
				color: $formsElementBorderColorActive;
			}
		}
	}

	&.grouped {
		& > .element {
			&:after {
				bottom: 0;
			}
		}
	}

	&.multi {
		& > .element {
			height: auto;
			min-height: $formsElementHeight;
		}
	}

	.disabled {
		pointer-events: none;
		background: none;
	}

	&.noLabel,
	.noLabel & {
		padding-top: 0;
	}
}

.dropdownOpen.uiSelect > .element,
.dropdownOpen.uiSelectDropdown {
	border-color: $formsElementBorderColorActive;
}

.focus.uiSelect > .element,
.focus.uiSelectDropdown {
	border-color: $formsElementBorderColorActive;
	outline: none;
}

.invalid.uiSelect > .element,
.invalid.uiSelectDropdown {
	border-color: $formsElementBorderColorInvalid;
}

.uiSelectDropdown {
	position: absolute;
	z-index: 20000;
	@include box-sizing(border-box);
	border: $formsElementBorderWidth solid $formsElementBorderColor;
	border-top: none;
	border-bottom-left-radius: $formsElementBorderRadius;
	border-bottom-right-radius: $formsElementBorderRadius;
	background: $white;

	.options {
		max-height: $formsSelectDropdownMaxHeight;
		overflow: scroll;
		color: $neutral;
		@include transition(max-height $transitionLengthShort);
		@include transition-timing-function(easeInOut);

		.header {
			margin-left: $formsSelectDividerInset;
			margin-right: $formsSelectDividerInset;
			margin-bottom: 0;
			padding: $formsElementItemPadding ($formsElementItemPadding - $formsSelectDividerInset) ($formsElementItemPadding/2 - $compactArtifactHeight);
			border-bottom: 1px solid $borderVeryWeak;
			color: $gunmetalWeak;
		}

		.option {
			padding: $formsElementItemPadding;
			cursor: pointer;

			&:hover {
				background: $formsElementItemBackgroundHover;
			}

			&:active {
				background: $formsElementItemBackgroundActive;
			}

			&.ng-enter {
				@include transition(opacity $transitionLengthMedium);
				opacity: 0;

				&.ng-enter-active {
					opacity: 1;
				}
			}

			&.ng-enter-stagger {
				@include transition-delay(40ms);
			}
		}

		.loader {
			position: absolute;
			left: 50%;
			top: $formsSelectDropdownMetaPaddingVertical;
			margin-left: -$formsSelectLoaderSize/2;
			@include transition(opacity $transitionLengthMedium);

			&.ng-hide-remove {
				opacity: 0;

				&.ng-hide-remove-active {
					opacity: 1;
				}
			}

			&.ng-hide-add {
				opacity: 1;

				&.ng-hide-add-active {
					opacity: 0;
				}
			}
		}

		.meta {
			text-align: center;
			padding: 5px;
		}
	}

	.action {
		position: relative;
		padding: ($formsElementItemPadding + 1px) $formsElementItemPadding $formsElementItemPadding;
		text-align: center;
		color: $blue;
		cursor: pointer;

		&:hover {
			background: $formsElementItemBackgroundHover;
		}

		&:active {
			background: $formsElementItemBackgroundActive;
		}
	}

	&.hasOptions {
		.action {
			padding: ($formsElementItemPadding + 2px) $formsElementItemPadding $formsElementItemPadding;	// Account for top border

			/* Divider border */
			&:before {
				content: '';
				position: absolute;
				left: $formsSelectDividerInset;
				right: $formsSelectDividerInset;
				top: 0;
				height: 1px;
				background: rgba($gunmetalWeak, 0.5);
			}
		}
	}

	.uiScrollDot {
		.scrollBackground,
		.scrollDot {
			opacity: 1;
			@include transition(opacity $transitionLengthMedium);
		}
	}

	&.loading {
		.options {
			min-height: $formsSelectDropdownMinHeight;
			max-height: $formsSelectDropdownMinHeight;
		}

		.uiScrollDot {
			.scrollBackground,
			.scrollDot {
				opacity: 0;
			}
		}
	}

	&.multi {
		.options {
			.option {
				// Enforce a body1 style on checkboxes, though the
				// body2 style is used in the ui-checkbox markup
				.subhead {
					font-size: $bodyFontSize;
					line-height: $bodyLineHeight;
					margin-top: -$bodyArtifactHeight;
					margin-bottom: -$bodyDescenderHeight;
				}

				label {
					padding-left: $formsCheckboxSize + 7px;

					&:before,
					&:after {
						top: ($bodyArtifactHeight + $bodyBaselineHeight - $formsCheckboxSize) / 2;
					}
				}

				/*height: $formsCheckboxSize;
				padding-top: $multiCheckboxPadding;
				padding-bottom: $multiCheckboxPadding;

				label {
					display: block;
					height: $formsCheckboxSize + 2*$multiCheckboxPadding;
					margin: -$multiCheckboxPadding 0;
					padding: ($multiCheckboxPadding + 4px) 0 $multiCheckboxPadding ($formsCheckboxSize + $formsCheckboxMargin);

					&:before,
					&:after {
						top: $multiCheckboxPadding;
					}
				}*/
			}
		}
	}
}
