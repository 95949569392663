
$menuMaxWidth: 300px;
$menuPaddingHorizontal: 12px;
$menuPaddingVertical: 10px;
$menuItemPaddingLeft: 28px;
$menuItemPaddingRight: $menuItemPaddingLeft;
$menuBorderWidth: 1px;
$menuBorderColor: rgba(0,0,0,0.12);
$menuOffset: 10px;
$menuCalloutSize: 8px;
$menuBackground: white;
$menuBorderRadius: $formsElementBorderRadius;
$menuBoxShadow: 0 0 12px rgba(0, 0, 0, 0.16);

.uiMenuDropdown {
  display: inline-block;
  color: $gunmetalWeak;
  cursor: pointer;
  @include align-items(center);

  &:hover {
    color: $gunmetalNeutral;
  }

  &:active {
    color: $gunmetalStrong;
  }
  
  &.uiMenuSelect {
    @include display-flex;
      display: -ms-flexbox;

    &:after {
      content: '\25BE';
      position: relative;
      display: inline-block;
      margin-left: 6px;
      font-size: 12px;
    }
    
    &.hasValue {
      color: #738694;
      
      &:hover {
        color: #5E6E7A;
      }
      
      &:active {
        color: #4A5661;
      }
    }
    
    &.open {
      span {
        &:after {
          @include transform(rotateZ(180deg));
          top: -1px;
        }
      }
    }
  }
}

.uiMenuDropdownTray {
	position: absolute;
	max-width: $menuMaxWidth;
  border: $menuBorderWidth solid $menuBorderColor;
	z-index: 10000000;
  border-radius: $menuBorderRadius;
  background: $menuBackground;
  @include box-shadow($menuBoxShadow);
  
  // Avoid flicker on initial positioning
  opacity: 0;
  &.ready { opacity: 1; }

	.content {
		-webkit-font-smoothing: antialiased;
	}

	&:before {
		content: '';
		position: absolute;
    width: $menuCalloutSize;
    height: $menuCalloutSize;
    border: $menuBorderWidth solid $menuBorderColor;
    z-index: 1;
    background: $menuBackground;
    @include box-shadow($menuBoxShadow);
    @include transform(rotateZ(45deg));
	}

  &:after {
    content: '';
    position: absolute;
    width: 2*$menuCalloutSize;
    height: $menuCalloutSize;
    z-index: 2;
    background: $menuBackground;
  }

	&.se {
		margin-top: $menuOffset;

		&:before,
    &:after {
			left: $menuPaddingHorizontal;
		}

    &:before {
      @include transform(translateY($menuCalloutSize/2 + $menuBorderWidth) rotateZ(45deg));
      bottom: 100%;
      margin-left: -($menuCalloutSize/2 + $menuBorderWidth);
    }

    &:after {
      top: 0;
      margin-left: -($menuCalloutSize + $menuBorderWidth);
    }
	}

	&.sw {
		margin-top: $menuOffset;

		&:before,
    &:after {
			right: $menuPaddingHorizontal;
		}

    &:before {
      @include transform(translateY($menuCalloutSize/2 + $menuBorderWidth) rotateZ(45deg));
      bottom: 100%;
      margin-right: -($menuCalloutSize/2 + $menuBorderWidth);
    }

    &:after {
      top: 0;
      margin-right: -($menuCalloutSize + $menuBorderWidth);
    }
	}
  
  &.s {
    @include transform(translateX(-50%));
		margin-top: $menuOffset;

		&:before,
    &:after {
			left: 50%;
		}

    &:before {
      @include transform(translateY($menuCalloutSize/2 + $menuBorderWidth) rotateZ(45deg));
      bottom: 100%;
      margin-left: -($menuCalloutSize/2 + $menuBorderWidth);
    }

    &:after {
      top: 0;
      margin-left: -($menuCalloutSize + $menuBorderWidth);
    }
	}

  .uiMenuDropdownItem {
    @include body();
    display: block;
    position: relative;
    z-index: 3;
    margin-top: 0;
    margin-bottom: 0;
    padding: ($menuPaddingVertical - $bodyArtifactHeight) $menuPaddingHorizontal ($menuPaddingVertical - $bodyDescenderHeight);
    color: $neutral;
    background: $menuBackground;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: $menuMaxWidth - $menuItemPaddingLeft - $menuPaddingHorizontal;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: $menuBorderRadius;
      border-top-right-radius: $menuBorderRadius;
    }

    &:last-child {
      border-bottom-left-radius: $menuBorderRadius;
      border-bottom-right-radius: $menuBorderRadius;
    }

    &.disabled {
      color: $gunmetalWeak;
      cursor: default;

      &:hover {
        background: $menuBackground;
        color: $gunmetalWeak;
      }
    }

    &:hover {
      background: rgba($gunmetalWeak, 0.1);
      color: $strong;
    }

    &:active {
      background: rgba($gunmetalWeak, 0.2);
      color: $veryStrong;
    }
    
    &.uiMenuDropdownToggle {
      padding-left: $menuItemPaddingLeft;
      // padding-right: $menuItemPaddingRight;
      
      .check {
        position: absolute;
        display: none;
        top: 50%;
        left: 7px;
        margin-top: -7px;
        font-size: 14px;
      }
      
      &:hover {
        .check {
          display: block;
        }
      }
    }
  }
  
  &:not(:hover) {
    .uiMenuDropdownItem {
      &.uiMenuDropdownToggle {
        &.active {
          .check {
            display: block;
          }
        }
      }
    }
  }
}
