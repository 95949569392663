@import "_imports";
$headerUnit-height: 510px;
$headerUnit-height-tablet: 420px;
$headerUnit-height-mobile: 310px;

.page#about, .page#architecture-genome {
	.display3+.display1, .display3+.module+.display1, .display3+.module+h3, .display3+h3, h1+.display1, h1+.module+.display1, h1+.module+h3, h1+h3 {
		margin-top: 30px;
	}
	.contentUnit {
		background: transparent;
		.attribution {
			text-align:right;
			position: absolute;
			bottom: 30px;
			right: 30px;
			& > a.caption.attr {
				margin: 5px auto auto auto;
				width: auto;
				&:hover {
					color: #fff;
				}
			}
		}
		.textUnit {
			max-width: span(12);
			margin: 45px auto 130px auto;
			& div.architectureImage {
				margin: 60px auto auto;
				width: 100%;
				height: auto;
				position: relative;
			}
			& p {
				max-width: span(14);
				margin: 30px auto 0 auto;
			}
			& .image {
				margin: 60px auto auto auto;
				width: 100%;
				height: auto;
				&.mobileImage {
					display:none;
				}
			}
			& .uiButton {
				margin: 30px auto;
			}
			& a.block {
				margin: 30px 0 0 0;
			}
			&:last-child {
				margin-top: 0;
			}
		}
		&.headerUnit {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center 60%;
			color: $white;
			height: $headerUnit-height;
			padding: 0;
			position: relative;
			& .container {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				& h2.twentyfour {
					font-size: 24px;
					margin: 0px;
				}
				& .image {
					height: 40px;
				}
			}
		}
	}
	.span.full.shaded {
		background-color: #fefefe;
		padding: 95px 0;
		margin: 0;
		width: 100%;
		border-bottom: 1px solid $gunmetalVeryWeak;
		border-top: 1px solid $gunmetalVeryWeak;
	}
	.testimonials {
		margin: 50px auto 0px auto;
		max-width: span(14);
		p.padded {
			margin: auto;
		}
	}
}

/*
Only architecture-genome
*/

.page#architecture-genome {
	.infotext {
		display: none;
		margin: 20px auto auto auto;
		padding: 10px $formsElementPaddingHorizontal;
		border-radius: $formsElementBorderRadius;
		max-width: 70%;
		color: $formsElementBorderColorInvalid;
		background: $red-5;
	}
	.thanks {
		display: none;
	}
	.inline-block {
		display: inline-block;
	}
	h1.inline-block {
		margin-bottom: 10px;
	}
	sup {
		vertical-align: text-bottom;
		font-size: 16px;
		margin-left: 10px;
	}
	.contentUnit .textUnit {
		padding: 30px;
		margin-bottom: 30px;
		// text-align: left;
		& p {
			margin-top: 30px;
			// text-align: left;
		}
	}
	.contentUnit .textUnit .signupForm {
		& .uiText {
	    max-width: 500px;
			width: 50%;
			display: inline-block;
			margin-top: 15px;
			&>.element {
				height: 38px;
				padding: 5px 10px;
			}
			& .placeholder {
				padding: 18px 11px 0;
				text-align: left;
			}
		}
		.uiButton {
			display: inline-block;
			padding-left: 30px;
			margin:auto;
		}
	}
}

/* Tablet Styles */

@include susy-breakpoint($tablet, $desktopGrid) {
	.page#about {
		.contentUnit {
			.textUnit {
				max-width: span(14);
			}
			&.headerUnit {
				height: $headerUnit-height-tablet;
			}
		}
	}
	.page#architecture-genome .contentUnit .textUnit {
			padding: 0;
			max-width: 100%;
		}
}

@include susy-breakpoint($largePhone, $mobileGrid) {
	.page#about {
		.contentUnit {
			.textUnit {
				max-width: span(8);
				& p {
					max-width: span(8);
				}
			}
			&.headerUnit {
				height: $headerUnit-height-tablet;
			}
		}
	}
	.page#architecture-genome .contentUnit .textUnit {
			padding: 0;

		}
}

/* Phone Styles */

@include susy-breakpoint($smallPhone, $mobileGrid) {
	.page#architecture-genome {
		h1 {
			font-size: 48px;
			line-height: 1.4;
		}
		sup {
			vertical-align: top;
		}
		.contentUnit .textUnit {
			padding: 0;
			margin:0;
			max-width: 100%;
			h1 {
				font-size: 36px;
				margin-bottom: 20px;
			}
			p {
				margin: 0;
				max-width: 100%;
			}
		}
	}
	.page#about {
		.contentUnit {
			.textUnit {
				max-width: span(8);
				text-align: center;
				& .image {
					&.desktopImage {
						display: none;
					}
					&.mobileImage {
						display: block;
						&.inset {
							width: span(6);
						}
					}
				}
			}
			&.headerUnit {
				height: $headerUnit-height-mobile;
				& .container {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					text-align:center;
				}
				h1 {
					font-size: 48px;
				}
			}
		}
		.span.full.shaded {
			text-align: center;
		}
		.testimonials .image {
   			margin: auto auto 30px auto;
		}
	}
}
