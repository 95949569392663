
.uiPopup.uiModal {
	width: 100%;
	max-height: 100%;
	animation: modalEntry 0.3s;

	& > .header {
		@include display-flex;
		display: -ms-flexbox;
		border-bottom: $modalPopupHeaderBorderWidth solid $gunmetalVeryWeak;

		.left {
			width: 0;		// allows correct layout with white-space: nowrap on delete button
			@include flex-grow(1);
			@include flex-shrink(1);
		}

		.right {
			margin-left: 20px;

			.uiLinkButton {
				margin-top: $titleBaselineHeight - $titleArtifactHeight - $bodyBaselineHeight;
			}
		}
	}

	& > .middle {
		@include display-flex;
		display: -ms-flexbox;
		@include flex-grow(1);
		@include flex-shrink(1);
		width: 100%;
		overflow: hidden;

		& > .content {
			@include box-sizing(border-box);
			width: 100%;
			overflow: scroll;
		}
	}

	& > .buttons {
		border-top: $modalPopupFooterBorderWidth solid $gunmetalVeryWeak;
	}

	&.medium {
		font-weight: inherit;		// clash with medium font weight class

		& > .header {
			padding: $modalPopupMediumHeaderPaddingTop $modalPopupSmallPaddingHorizontal $modalPopupMediumHeaderPaddingBottom;
		}

		& > div > .content {
			padding: $modalPopupMediumContentPaddingVertical $modalPopupSmallPaddingHorizontal;
		}

		& > .buttons {
			padding: $modalPopupMediumFooterPaddingVertical $modalPopupSmallPaddingHorizontal;
		}
	}

	&.small {
		& > .header {
			padding: $modalPopupSmallHeaderPaddingTop $modalPopupSmallPaddingHorizontal $modalPopupSmallHeaderPaddingBottom;
		}

		& > div > .content {
			padding: $modalPopupSmallContentPaddingVertical $modalPopupSmallPaddingHorizontal;
		}

		& > .buttons {
			padding: $modalPopupSmallFooterPaddingVertical $modalPopupSmallPaddingHorizontal;
		}
	}
}

.uiPopupWrapper.exiting {
	.uiPopup.uiModal {
		animation: modalExit 0.3s;
	}
}


/*
 * Desktop
 */

@include breakpoint($desktop) {
	.uiPopup.uiModal {
		&.medium {
			& > .header,
			& > div > .content,
			& > .buttons {
				padding-left: $modalPopupMediumPaddingHorizontal;
				padding-right: $modalPopupMediumPaddingHorizontal;
			}
		}

		&.small {
			max-width: $modalPopupSmallMaxWidth;
		}
	}
}


/*
 * Not phone
 */

@include breakpoint($notPhone) {
	.uiPopupWrapper.modal {
		padding: $modalPopupMediumVerticalMargin 0;
	}

	.uiPopup.uiModal {
		&.medium {
			max-width: $modalPopupMediumMaxWidth;
		}

		&.small {
			max-width: $modalPopupSmallMaxWidth;
		}
	}
}


/*
 * Phone
 */

@include breakpoint($phone) {
	.uiPopup.uiModal {
		height: 100%;

		.buttons {
			.uiButton {
				display: block;
				width: 100%;

				button {
					width: 100%;
				}
			}

			.uiButton + .uiButton {
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}
}


/*
 * Modernizr (IE10)
 */
.flexboxtweener {
	.uiPopup.uiModal {
		& > .middle {
			@include flex(1 1);
		}
	}
}
