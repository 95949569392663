@import "_imports";

body.fullscreen {
  height: 100vh;
  overflow: hidden;
}

#adkHeader.fixed #header-row {
  height: 3.3rem;
}

#content {
  overflow: auto;

  &.fixedHeader {
    overflow: initial;
    padding-top: $headerHeight;
  }
}

.ng-hide.ng-hide-animate {
  display: none !important;
}

/**
 * For show-ui-loader directive
 */
.showUiLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	max-width: 100vw;
	max-height: 100vh;
	text-align: center;
	z-index: 1000;
}
// Hide overflow when loader is showing
.showUiLoader-loading {
	overflow: hidden;
}

.page {
	@include display-flex;
	display: -ms-flexbox;
	@include flex-direction(column);
	@include flex-grow(1);
	@include clearfix();
	position: relative;
	width: 100%;
	z-index: 2;
	background: white;
	@include transition(margin-top 250ms);

	&.pad {
		padding-top: $pageMarginTop;
	}

	.uiActionBarContainer {
		& + .content {
			padding-top: 34px;
		}
	}

	.ctaVisible & {
		// cta is two lines on phone
		@include bp(phone) {
			margin-top: $headerHeight + $ctaHeightPhone;
		}

		// one line on other media
		@include bp(notPhone) {
			margin-top: $headerHeight + $ctaHeight;
		}

		&.hasHero {
			margin-top: $ctaHeight;
		}
	}

	.mainPlaceholder {
    display: block;
    text-align: center;

    .uiLoader {
    	margin-bottom: 25px;
    }
  }
}

body .uiFlashContainer {
	margin-left: auto;
	margin-right: auto;

	.uiFlash {
		@include susy-breakpoint($desktop, $desktopGrid) {
			@include span(full);
			max-width: none;
		}

		@include susy-breakpoint($mobile, $mobileGrid) {
			@include span(full);
		}
	}
}

.ngProgressLiteBar {
	top: $headerHeight;
	background: $primary;
	@include transition(top 250ms);

	.ngProgressLiteBarShadow {
		display: none !important;
	}
}

.ctaVisible {
	.ngProgressLite {
		.ngProgressLiteBar {
			top: $headerHeight + $ctaHeight;
		}
	}
}

#unsupportedBrowser {
	display: none;
}

/*
 * Modernizr (IE10)
 */

.flexboxtweener {
	&,
	body {
		height: 100%;
	}
}


/**
 * Small phone styles
 */

@include breakpoint($smallPhone) {
	#content {
		& > .uiHero {
			margin-top: 0;
		}
	}
}
