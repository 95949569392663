// Text input
.uiText {
	position: relative;
	padding-top: $formsLabelHeight;

	& > .element {
		@include display-flex;
		display: -ms-flexbox;
		@include box-sizing(border-box);
		width: 100%;
		height: $formsElementHeight;
		padding: $formsElementPaddingTop $formsElementPaddingHorizontal 0;
		border: $formsElementBorderWidth solid $formsElementBorderColor;
		border-radius: $formsElementBorderRadius;
		z-index: 2;
		background: $white;
		cursor: pointer;
		@include transition(border-color $formsTransitionLength);

		&:hover {
			border-color: $formsElementBorderColorHover;
		}

		.inlineLabel {
			margin-right: 4px;
			line-height: $formsElementHeight - $formsElementPaddingTop - $formsElementBorderWidth;
			white-space: nowrap;

			&.glyph {
				margin-top: 0;
				margin-right: $formsElementItemPadding;
				font-size: 19px;
			}
		}

		input {
			@include flex-grow(1);
			padding: 0;
			font-size: $formsElementFontSize;
			color: $formsElementColor;
			letter-spacing: $formsElementLetterSpacing;


			border: none;
			outline: none;
			-webkit-appearance: none;
		}
	}

	&.focus {
		& > .element {
			border-color: $formsElementBorderColorActive;
			outline: none;
		}
	}

	&.disabled {
		& > .element {
			border-color: $formsElementBorderColorDisabled;
			background: none;

			input {
				background: none;
				pointer-events: none;
			}
		}
	}

	&.invalid {
		& > .element {
			border-color: $formsElementBorderColorInvalid;
		}
	}

	&.noLabel,
	.noLabel & {
		padding-top: 0;
	}

	&.hasValue {
		input {
			cursor: text;
		}
	}
}

input:focus {
	outline: none;
}
