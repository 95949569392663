@import "_imports";

$swatchImageTransitionLength: 1s;
$swatchAnimationLength: 3s;

.swatch {
	min-height: 1px;

	.swatchBlocks {
		@include clearfix();

		.swatchBlock {
			margin-bottom: 30px;

			@include bp(mobile) {
				margin-bottom: 15px;
			}

			.swatchCard {
				width: 100%;

				& > .image {
					@include placeholderBackground();
					background-size: 200px;

					.content {
						opacity: 0;
						@include transition(opacity $swatchImageTransitionLength);
					}
				}
			}

			// Fade in images when card is loaded
			&.contentLoaded {
				.swatchCard .image .content {
					opacity: 1;
				}
			}
		}
	}

	// load more results button
	.loadMoreWrapper {
		@include gridMarginLeft(0,0,1);

		.loadMore {
			display: block;
			margin-top: 36px;
			text-align: center;

			button {
				position: relative;
				
				&.progress {
					.loader {
						opacity: 1;
					}

					span {
						opacity: 0
					}
				}

				span,
				.loader {
					@include transition(opacity 150ms);
				}

				.loader {
					position: absolute;
					left: 50%;
					top: 50%;
					margin-left: -2*$circleSizeMedium;
					margin-top: -$circleSizeMedium/2;
					opacity: 0;
				}

				span {
					opacity: 1;
				}
			}	
		}
	}
}

// progress animation
@keyframes buttonProgress {
	from { transform: rotate(0); }
	to { transform: rotate(360deg); }
}

@-webkit-keyframes buttonProgress {
	from { -webkit-transform: rotate(0); }
	to { -webkit-transform: rotate(360deg); }
}