@import "_imports";
$headerUnit-height: 510px;
$headerUnit-height-tablet: 420px;
$headerUnit-height-mobile: 310px;

.page#about {
	.contentUnit {
		.attribution {
			text-align:right;
			position: absolute;
			bottom: 30px;
			right: 30px;
			& > a.caption.attr {
				margin: 5px auto auto auto;
				width: auto;
			}
		}
		.textUnit {
			max-width: span(12);
			margin: 45px auto 130px auto;
			& div.architectureImage {
				margin: 60px auto auto;
				width: 100%;
				height: auto;
				position: relative;
			}
			& p {
				max-width: span(14);
				margin: 30px auto 0 auto;
			}
			& .image {
				margin: 60px auto auto auto;
				width: 100%;
				height: auto;
				&.mobileImage {
					display:none;
				}
			}
			& .uiButton {
				margin: 30px auto;
			}
			& a.block {
				margin: 30px 0 0 0;
			}
		}
		&.headerUnit {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center 60%;
			color: $white;
			height: $headerUnit-height;
			padding: 0;
			position: relative;
			& .container {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				& h2.twentyfour {
					font-size: 24px;
					margin: 0px;
				}
				& .image {
					height: 40px;
				}
			}
		}
	}
	.span.full.shaded {
		background-color: #f8f9fa;
		padding: 95px 0;
		margin: 0;
		width: 100%;
	}
	.testimonials {
		margin: 50px auto 0px auto;
		max-width: span(14);
		p.padded {
			margin: auto;
		}
	}
}

/* Tablet Styles */

@include susy-breakpoint($tablet, $desktopGrid) {
	.page#about {
		.contentUnit {
			.textUnit {
				max-width: span(14);
			}
			&.headerUnit {
				height: $headerUnit-height-tablet;
			}
		}
	}
}

@include susy-breakpoint($largePhone, $mobileGrid) {
	.page#about {
		.contentUnit {
			.textUnit {
				max-width: span(8);
				& p {
					max-width: span(8);
				}
			}
			&.headerUnit {
				height: $headerUnit-height-tablet;
			}
		}
	}
}

/* Phone Styles */

@include susy-breakpoint($smallPhone, $mobileGrid) {
	.page#about {
		.contentUnit {
			.textUnit {
				max-width: span(8);
				text-align: center;
				& .image {
					&.desktopImage {
						display: none;
					}
					&.mobileImage {
						display: block;
						&.inset {
							width: span(6);
						}
					}
				}
			}
			&.headerUnit {
				height: $headerUnit-height-mobile;
				& .container {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					text-align:center;
				}
			}
		}
		.span.full.shaded {
			text-align: center;
		}
		.testimonials .image {
   			margin: auto auto 30px auto;
		}
	}
}
