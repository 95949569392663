
.uiImage {

	&.thumbnail {
		.imageContainer {
			.buttons {
				display: none;
			}
		}
	}

	&.small {
		.imageContainer {
			.buttons {
				@include justify-content(flex-end);
				padding: 0 12px 12px;

				.uiButton {
					& + .uiButton {
						margin-left: 12px;
					}
				}
			}
		}
	}

	&.medium,
	&.large {
		.imageContainer {
			.buttons {
				@include justify-content(center);
				padding: 0 20px 20px;

				.uiButton {
					width: 100px;

					& + .uiButton {
						margin-left: 17px;
					}

					button {
						width: 100%;
					}
				}
			}
		}
	}

	.imageContainer {
		position: relative;

		.image {
			display: block;
			width: 100%;

			&.cropped {
				height: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
			
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($gunmetalWeak, 0.14);
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			opacity: 0;
			background: rgba(black, 0.34);
			@include background-image(linear-gradient(rgba(black, 0), rgba(black, 0.1)));
			@include transition(all 0.3s);

			&.hasClickHandler {
				cursor: pointer;
			}
		}
		
		.processing {
				@include display-flex;
					display: -ms-flexbox;
					
				@include align-items(center);
					-ms-flex-align: center;
					
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 10px;
				text-align: center;
				background: rgba($gunmetalWeak, 0.1);
				
				span {
					width: 100%;
				}
			}

		.buttons {
			@include display-flex;
			display: -ms-flexbox;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&:hover {
			.overlay {
				opacity: 1;
			}
		}
	}

	.imageContainer + .caption {
		padding-top: 14px;
	}

	.meta {
		@include clearfix;

		span {
			display: inline;

			&.credit {
				position: relative;
				margin-left: 2px;
				padding-left: 7px;

				&:before {
					content: '';
					position: absolute;
					top: $captionArtifactHeight - 2;
					left: 0;
					width: 0;
					height: 11px;
					border-left: 1px solid $veryWeak;
				}
			}
		}

		span + span {
			margin-left: 10px;
		}
	}
}
