
$filePreviewWidth: 250px;
$filePreviewHeight: 140px;

.uiFilePreview {
	display: inline-block;
	position: relative;
	width: $filePreviewWidth;
	
	.preview {
		.imageContainer {
			height: $filePreviewHeight;
		}
	}
	
	.remove {
		$size: 20px;
		position: absolute;
		width: $size;
		height: $size;
		right: -$size/2;
		top: -$size/2;
		z-index: 3;
		border-radius: $size/2;
		background: $gunmetalWeak;
		cursor: pointer;

		&:hover {
			background: $gunmetalNeutral; 
		}

		&:active {
			background: $gunmetalStrong;
		}

		.glyph {
			display: block;
			width: 100%;
			line-height: $size;
			text-align: center;
			color: white;
			@include transform(rotateZ(45deg));
		}
	}
	
	.meta {
		@include clearfix; 
		margin-top: 22px;
		@include transition(opacity 0.3s);
		
		.glyph {
			font-size: 20px;
			vertical-align: sub;
			margin-left: 2px;
		}
		
		.add,
		.edit,
		.preview {
			margin-top: 0;
		}
		
		.edit,
		.preview {
			.uiLinkButton {
				margin-top: 10px - $bodyArtifactHeight;
			}
		}
		
		.left {
			float: left;
		}
		
		.right {
			float: right;
		}
	}
	
	&.editing {
		.meta {
			opacity: 0.3;
			
			&.editing {
				opacity: 1;
			}
		}
	}
}