
.uiTags {
	overflow: auto;

	.uiTag {
		float: left;
		margin-left: $formsTagMargin;
	}
}

.uiTag {
	display: inline-block;
	border-radius: $borderRadiusSmall;
	color: $strong;
	background: $formsTagBackground;

	.label {
		display: block;
		padding: 6px;
		cursor: default;
		white-space: nowrap;
		overflow: hidden;
	}

	&.hasClick {
		.label {
			cursor: pointer;

			&:hover {
				background: rgba(0,0,0,0.08);
			}

			&:active {
				background: rgba(0,0,0,0.12);
			}
		}
	}

	&.hasRemove {
		position: relative;
		padding-right: $formsTagRemovePadding + $formsTagRemoveWidth + $formsTagRemoveMargin;

		.remove {
			position: absolute;
			top: 0;
			right: 0;
			height: $formsTagHeight;
			line-height: $formsTagHeight;
			padding: 0 $formsTagRemovePadding;
			margin: 0;
			border: none;
			font-size: 11px;
			color: $veryWeak;
			background: none;
			cursor: pointer;
			@include glyph();

			&:hover {
				color: $red;
				background: rgba(0,0,0,0.08);
			}

			&:active {
				background: rgba(0,0,0,0.12);
			}

			&:active,
			&:focus {
				outline: none;
			}
		}
	}
}
