@import "_imports";

@keyframes circleOneAnimation {
	0% { opacity: 0.5; }
	25% { opacity: 1.0; }
	50% { opacity: 0.5; }
	75% { opacity: 0.5; }
	100% { opacity: 0.5; }
}

@-webkit-keyframes circleOneAnimation {
	0% { opacity: 0.5; }
	25% { opacity: 1.0; }
	50% { opacity: 0.5; }
	75% { opacity: 0.5; }
	100% { opacity: 0.5; }
}

@keyframes circleTwoAnimation {
	0% { opacity: 0.5; }
	25% { opacity: 0.5; }
	50% { opacity: 1.0; }
	75% { opacity: 0.5; }
	100% { opacity: 0.5; }
}

@-webkit-keyframes circleTwoAnimation {
	0% { opacity: 0.5; }
	25% { opacity: 0.5; }
	50% { opacity: 1.0; }
	75% { opacity: 0.5; }
	100% { opacity: 0.5; }
}

@keyframes circleThreeAnimation {
	0% { opacity: 0.5; }
	25% { opacity: 0.5; }
	50% { opacity: 0.5; }
	75% { opacity: 1.0; }
	100% { opacity: 0.5; }
}

@-webkit-keyframes circleThreeAnimation {
	0% { opacity: 0.5; }
	25% { opacity: 0.5; }
	50% { opacity: 0.5; }
	75% { opacity: 1.0; }
	100% { opacity: 0.5; }
}

.loader {
	&.small {
		width: 4*$circleSizeSmall;
		height: $circleSizeSmall;

		.circle1,
		.circle2,
		.circle3 {
			width: $circleSizeSmall;
			height: $circleSizeSmall;
			margin-left: $circleSizeSmall/3;
			border-radius: $circleSizeSmall/2;
		}
	}

	&.medium {
		width: 4*$circleSizeMedium;
		height: $circleSizeMedium;

		.circle1,
		.circle2,
		.circle3 {
			width: $circleSizeMedium;
			height: $circleSizeMedium;
			margin-left: $circleSizeMedium/3;
			border-radius: $circleSizeMedium/2;
		}
	}

	&.large {
		width: 4*$circleSizeLarge;
		height: $circleSizeLarge;

		.circle1,
		.circle2,
		.circle3 {
			width: $circleSizeLarge;
			height: $circleSizeLarge;
			margin-left: $circleSizeLarge/3;
			border-radius: $circleSizeLarge/2;
		}
	}

	&.huge {
		width: 4*$circleSizeHuge;
		height: $circleSizeHuge;

		.circle1,
		.circle2,
		.circle3 {
			width: $circleSizeHuge;
			height: $circleSizeHuge;
			margin-left: $circleSizeHuge/3;
			border-radius: $circleSizeHuge/2;
		}
	}

	&.primary {
		.circle1,
		.circle2,
		.circle3 {
			background: $primary;
		}
	}

	&.secondary {
		.circle1,
		.circle2,
		.circle3 {
			background: $secondary;
		}
	}

	&.gray {
		.circle1,
		.circle2,
		.circle3 {
			background: #888;
		}
	}

	.circle1,
	.circle2,
	.circle3 {
		display: inline-block;
		background: $primary;

		&:first-child {
			margin-left: 0;
		}
	}

	.circle1 {
		@include animate(circleOneAnimation $circleAnimationLength linear infinite);
	}

	.circle2 {
		@include animate(circleTwoAnimation $circleAnimationLength linear infinite);
	}

	.circle3 {
		@include animate(circleThreeAnimation $circleAnimationLength linear infinite);
	}
}