
$anchorLeft: 500px;
$anchorTop: 300px;

$popupBackgroundOpacity: 1.00;
$popupShadowSize: 4px;

$popupCalloutSize: 9px;
$popupCalloutSizeRotated: 1.414*$popupCalloutSize;
$popupCalloutOffset: 18px;
$popupCalloutDistance: 5px;

$modalPopupMediumVerticalMargin: 50px;
$modalPopupMediumMaxWidth: 740px;
$modalPopupMediumPaddingHorizontal: 95px;
$modalPopupMediumHeaderPaddingTop: 35px;
$modalPopupMediumHeaderPaddingBottom: 26px;
$modalPopupMediumContentPaddingVertical: 43px;
$modalPopupMediumFooterPaddingVertical: 18px;

$modalPopupSmallVerticalMargin: 50px;
$modalPopupSmallMaxWidth: 596px;
$modalPopupSmallPaddingHorizontal: 25px;
$modalPopupSmallHeaderPaddingTop: 35px;
$modalPopupSmallHeaderPaddingBottom: 26px;
$modalPopupSmallContentPaddingVertical: 33px;
$modalPopupSmallFooterPaddingVertical: 18px;

$modalPopupHeaderBorderWidth: 1px;
$modalPopupFooterBorderWidth: 1px;

@import "popups/_mask";
@import "popups/_modal";
@import "popups/_tooltip";
@import "popups/_flash";

// Entry/exit animations
@-webkit-keyframes modalEntry {
	0% {
		@include transform(translateZ(-14px));
		opacity: 0;
	}

	40% {
		opacity: 1;
		animation-timing-function: ease-out;
	}

	100% {
		@include transform(none);
	}
}

@-webkit-keyframes modalExit {
	0% {
		@include transform(none);
		animation-timing-function: ease-out;

	}

	60% {
		opacity: 1;
		animation-timing-function: linear;
	}

	100% {
		opacity: 0;
		@include transform(translateZ(-14px));
	}
}

// Popup wrapper
.uiPopupWrapper {
	@include box-sizing(border-box);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	@include display-flex;
	display: -ms-flexbox;
	justify-content: center;
	align-items: center;
	perspective: 1000px;
	perspective-origin: 50% 50%;
}

// Popup style
.uiPopup {
	@include display-flex;
	display: -ms-flexbox;
	@include flex-direction(column);
	background: rgba($white, $popupBackgroundOpacity);
	@include box-sizing(border-box);
	@include box-shadow(0px 0px $popupShadowSize 0px rgba(0, 0, 0, 0.2));

	&:focus {
		outline: none;
	}

	.header,
	.buttons {
		@include flex-shrink(0);
	}

	.header {
		.note {
			&.primary {
				color: $blue;
			}

			&.success {
				color: $green;
			}

			&.danger {
				color: $red;
			}
		}
	}

	.buttons {
		@include box-sizing(border-box);
		border-top: 1px solid $gunmetalVeryWeak;
		text-align: center;

		.uiButton {
			margin-left: $marginSmall;
			margin-top: 0;

			&:first-child {
				margin-left: 0;
			}

			button {
				display: block;
				min-width: 165px;
			}
		}
	}
}
