@import "_imports";

$cardWidth: 275px;
$cardImageHeight: 200px;
$cardPadding: $paddingMedium;
$cardBorder: none;
$cardBackground: rgba($gunmetalWeak, 0.1);
$cardBigInnerMargin: 15px;
$cardLittleInnerMargin: $marginSmall;
$cardThumbnailSize: 34px;

.swatchCard.product {
	border: $cardBorder;

	& > a > .uiImage {
		.image {
			height: $cardImageHeight;	
		}
	}
	
	.image {
		position: relative;

		& > .content {
			width: 100%;
			height: $cardImageHeight;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	& > .content {
		background: $cardBackground;
		padding: $cardPadding;

		.subhead,
		.byline {
			display: block;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.byline {
			margin-top: 8px - $bodyArtifactHeight;
		}
		
		.thumbnails {
			margin-top: $cardBigInnerMargin;
		}
		
		.caption {
			margin-top: $cardBigInnerMargin - $captionArtifactHeight;
		}

		.thumbnails {
			min-height: $cardThumbnailSize;
			list-style: none;
			overflow: auto;

			li {
				float: left;
				display: block;
				margin-right: $marginSmallest;

				a {
					display: block;
					width: $cardThumbnailSize;
					padding-top: $cardThumbnailSize;
					height: 0;
					overflow: hidden;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
		}
	}
}