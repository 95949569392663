
.uiHeroPreview {
	@include display-flex;
	display: -ms-flexbox;
	
	.preview {
		position: relative;
		background-repeat: no-repeat; 
		background-size: contain;
		
		&.mobile {
			display: block;
			width: 19.59%;
			padding-top: 41.22%;
			background-image: url('/images/hero-preview-phone.svg');
			
			.frame {
				top: 14.43%;
				left: 8.10%;
				width: 84.83%;
				height: 36.07%;
			}
		}
		
		&.desktop {
			display: block;
			width: 71.49%;
			padding-top: 41.22%;
			margin-left: 8.92%;
			background-image: url('/images/hero-preview-laptop.svg');
			
			.frame {
				top: 5.94%;
				left: 11.90%;
				width: 76.10%;
				height: 38.94%;
			}
		}
		
		.frame {
			position: absolute;
			overflow: hidden;
			width: 100%;
			height: 0; 
			background: #eee;
			cursor: crosshair;
			
			.image {
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-repeat: no-repeat;
				@include transition(all 0.3s);
			}
		}
	}
}