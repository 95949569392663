
$formsButtonLoaderSizeMedium: 18px;
$formsButtonLoaderSizeLarge: 20px;
$formsButtonLoaderSizeInline: 18px;

.uiButton {
	button {
		@include box-sizing(border-box);
		
		position: relative;
		padding: 0;
		border: none;
		border-radius: $formsElementBorderRadius;
		vertical-align: $formsElementVerticalAlign;
		font-family: $formsButtonFontFamily;
		color: $white;
		cursor: pointer;
		overflow: hidden;

		&:focus {
			outline: none;
		}

		&[disabled] {
			pointer-events: none;

			&:not(.loading) {
				&.default,
				&.primary,
				&.success,
				&.danger,
				&.facebook,
				&.google,
				&.twitter,
				&.linkedin {
					&.normal {
						background: #dee3e6;
						color: white;
						border: none;	
					}
					
					&.outline {
						border-color: #dee3e6;
						color: #dee3e6;
					}
				}
			}
		}

		// Loader
		.uiLoader {
			position: absolute;
			left: 50%;
			top: 50%;
		}

		&.loading {
			.uiLoader {
				@include transform(translateY(0) !important);
			}
		}

		.uiLoader,
		.wrapper {
			@include transition(transform $transitionLengthShort);
		}

		.wrapper {
			@include display-flex;
				display: -ms-flexbox;
			@include align-items(center);
			width: 100%;
			height: 100%;
		}

		// Icon
		&.hasIcon:not(.hasLabel) {
			@include justify-content(center);
			
			.icon {
				text-align: center;
			}
		}
		
		.icon {
			position: relative;
			display: block;
			@include flex-grow(1);
			text-align: right;
		}

		// Label
		&.hasLabel:not(.hasIcon) {		
			.label {
				text-align: center
			}
		}
		
		.label {
			@include flex-grow(1);
			white-space: nowrap;
			-webkit-font-smoothing: subpixel-antialiased;
			text-align: left;
		}	

		// Styles
		&.subtle {
			border: none;

			&.default {
				background: white;

				&:hover {
					background: white;
					color: $blue;
				}
			}
		}
		
		&.outline {
			background: none;
		}

		// Sizes
		&.medium {
			height: $formsButtonHeightMedium;
			font-weight: inherit;	// clash with font weight class

			&.hasIcon:not(.hasLabel) {
				width: $formsButtonHeightMedium;

				.icon {
					font-size: 19px;
				}
			}

			&.hasLabel {
				padding-left: $formsButtonPaddingMedium;
				padding-right: $formsButtonPaddingMedium;

				.icon {
					font-size: 19px;
					margin-right: 6px;
				}
			}
			
			.icon.glyph {
				top: 1px;
			}

			.uiLoader {
				margin-left: -$formsButtonLoaderSizeMedium/2;
				margin-top: -$formsButtonLoaderSizeMedium/2;
				@include transform(translateY($formsButtonHeightMedium/2 + $formsButtonLoaderSizeMedium/2));
			}

			&.loading {
				.wrapper {
					@include transform(translateY(-($formsButtonHeightMedium/2 + $formsButtonLoaderSizeMedium/2)));
				}
			}
		}

		&.large {
			height: $formsButtonHeightLarge;

			&.hasIcon:not(.hasLabel) {
				width: $formsButtonHeightLarge - 1px;

				.icon {
					left: 0;
					font-size: 21px;
				}
			}

			&.hasLabel {
				padding-left: $formsButtonPaddingLarge;
				padding-right: $formsButtonPaddingLarge;

				.icon {
					margin-right: 5px;
					font-size: 22px;
				}
			}

			.uiLoader {
				margin-left: -$formsButtonLoaderSizeLarge/2;
				margin-top: -$formsButtonLoaderSizeLarge/2;
				@include transform(translateY($formsButtonHeightLarge/2 + $formsButtonLoaderSizeLarge/2));
			}

			&.loading {
				.wrapper {
					@include transform(translateY(-($formsButtonHeightLarge/2 + $formsButtonLoaderSizeLarge/2)));
				}
			}
		}

		// Sizes
		&.inline {
			height: $formsButtonHeightInline;

			&.hasIcon:not(.hasLabel) {
				width: $formsButtonHeightInline;

				.icon {
					font-size: 17px;
				}
			}

			&.hasLabel {
				padding-left: $formsButtonPaddingInline;
				padding-right: $formsButtonPaddingInline;

				.icon {
					font-size: 16px;
					margin-right: 5px;
				}
			}

			.uiLoader {
				margin-left: -$formsButtonLoaderSizeInline/2;
				margin-top: -$formsButtonLoaderSizeInline/2;
				@include transform(translateY($formsButtonHeightInline/2 + $formsButtonLoaderSizeInline/2));
			}

			&.loading {
				.wrapper {
					@include transform(translateY(-($formsButtonHeightInline/2 + $formsButtonLoaderSizeInline/2)));
				}
			}
		}

		// Colors
		@mixin normalStyle($defaultColor, $hoverColor, $activeColor) {
			background: $defaultColor;

			&:hover {
				background: $hoverColor;
			}

			&:active {
				background: $activeColor;
			}
		}
		
		@mixin outlineStyle($defaultColor, $hoverColor, $activeColor) {
			color: $defaultColor;
			border: 1px solid $defaultColor;
			background: white;

			&:hover {
				color: $hoverColor;
				border-color: $hoverColor;
			}

			&:active {
				color: $activeColor;
				border-color: $activeColor;
			}
		}
		
		&.default {
			&.normal {
				@include normalStyle($titanium, $titanium1, $titanium2);
			}

			&.outline {
				@include outlineStyle($titanium, $titanium1, $titanium2);
			}
			
			&.subtle {
				color: $titanium;
			}
		}

		&.primary {
			&.normal {
				@include normalStyle($blue, $blue1, $blue2);	
			}
			
			&.outline {
				@include outlineStyle($blue, $blue1, $blue2);
			}
		}

		&.success {
			&.normal {
				@include normalStyle($green, $green1, $green2);	
			}
			
			&.outline {
				@include outlineStyle($green, $green1, $green2);
			}
		}

		&.danger {
			&.normal {
				@include normalStyle($red, $red1, $red2);	
			}
			
			&.outline {
				@include outlineStyle($red, $red1, $red2);
			}
		}

		&.facebook {
			background: #3b5998;

			&:hover {
				background: #35528c;
			}

			&:active {
				background: #2c4378;
			}
		}

		&.google {
			background: #dd4b39;

			&:hover {
				background: #d14434;
			}

			&:active {
				background: #bd402d;
			}
		}

		&.twitter {
			background: #00aced;

			&:hover {
				background: #00a8e0;
			}

			&:active {
				background: #0092cc;
			}
		}

		&.linkedin {
			background: #007ab8;
			border-bottom-color: shade(#007ab8, 20);

			&:hover {
				background: #006aa1;
			}

			&:active {
				background: #005581;
			}
		}
	}

	/**
	 * Styles for ui-menu-dropdown-button elements
	 *
	 * These styles apply to ui-button elements with the "ui-menu-dropdown-button" attribute
	 * See the ui-menu-dropdown-button directive for more info.
	 */
	&[ui-menu-dropdown-button="ui-menu-dropdown-button"] {
		.uiMenuDropdownItem {
			display: none;
		}

		button.hasLabel {
			&.inline {
				width: $formsButtonHeightInline;
				padding-left: 0;
				padding-right: 0;
			}

			&.large {
				width: $formsButtonHeightLarge;
				padding-left: 0;
				padding-right: 0;
			}

			&.medium {
				width: $formsButtonHeightMedium;
				padding-left: 0;
				padding-right: 0;
			}

			.icon {
				margin-right: 0;
			}
		}
	}
}