

/******************************************
 *
 * Colors
 *
 ******************************************/

$greyhue: 36;

$white:   hsl($greyhue, 0%, 100%);
$grey4:   hsl($greyhue, 0.5%, 96%);
$grey5:   hsl($greyhue, 1%, 95%);
$grey6:   hsl($greyhue, 2%, 96%);
$grey8:   hsl($greyhue, 1%, 92%);
$grey10:  hsl($greyhue, 2%, 88%);
$grey16:  hsl($greyhue, 2%, 84%);
$grey24:  hsl($greyhue, 2%, 76%);
$grey29:  hsl($greyhue, 2%, 71%);
$grey36:  hsl($greyhue, 3%, 64%);
$grey48:  hsl($greyhue, 3%, 52%);
$grey60:  hsl($greyhue, 3%, 40%);
$grey64:  hsl($greyhue, 4%, 36%);
$grey72:  hsl($greyhue, 4%, 28%);
$grey84:  hsl($greyhue, 4%, 16%);
$grey92:  hsl($greyhue, 5%, 8%);
$grey96:  hsl($greyhue, 5%, 4%);
$black:   hsl($greyhue, 5%, 0%);

$bodyBackground: #f5f4f4;
$trayBackground: #292827;

$ctaBackground: #faed4b;
$ctaBackgroundHover: #f2e007;

$mediumBlue: #0cb7fa;
$lightBlue: #57cefb;
$desatBlue: #0d80ad;

$darkGreen: #009370;
$mediumGreen: #00c697;

$darkYellow: #d9c906;
$mediumYellow: #faed4b;

$primary: $mediumBlue;
$linkColor: $primary;
$linkColorHover: $desatBlue;

$secondary: $mediumGreen;
$darkSecondary: $darkGreen;

$tertiary: $mediumYellow;
$darkTertiary: $darkYellow;


/******************************************
 *
 * Layout dimensions
 *
 ******************************************/

$pageMarginTop: 20px;
$pageMarginBottom: 100px;

$heroHeight: 612px;
$heroHeightShort: 459px;
$heroContentWidth: 60%;
$heroContentMaxWidth: 1000px;
$heroControlHeight: 55px;
$heroButtonWidth: 20%;
$heroTitleMarginBottom: 40px;
$heroTitleLineHeight: 55px;

$footerHeightDesktop: 308px;
$footerHeightMobile: 370px;


/******************************************
 *
 * Responsive grid
 *
 ******************************************/

/*
 * Responsive breakpoints
 */

$breakpointZero: 1400px;		// whaaaaat so big
$breakpointOne: 1200px;			// desktops
$breakpointTwo: 1000px;			// landscape tablets/small desktops
$breakpointThree: 768px;		// portrait tablets/large phones
$breakpointFour: 480px;			// small phones

$smallPhone: 0 $breakpointFour;
$largePhone: ($breakpointFour + 1) $breakpointThree;
$phone: 0 $breakpointThree;
$tablet: ($breakpointThree + 1) $breakpointTwo;
$laptop: ($breakpointTwo + 1) $breakpointOne;
$smallExternal: ($breakpointOne + 1) $breakpointZero;
$largeExternal: ($breakpointZero + 1);

$mobile: 0 $breakpointTwo;
$external: ($breakpointOne + 1);
$desktop: ($breakpointTwo + 1);
$notPhone: ($breakpointThree + 1);


/*
 * Grid
 */

$mobileColumns: 8;
$mobileGutter: 15px;
$desktopColumns: 16;
$desktopSidebarColumns: 3;
$desktopRailColumns: $desktopSidebarColumns;
$desktopMainColumns: $desktopColumns - $desktopSidebarColumns - $desktopRailColumns;

// Gutters
// Susy does not allow different outer and inner gutters.
// The max width of the layout is 1600px, however padding
// is applied to an outer container to simulate this larger
// outer gutter. The percentage of this outer gutter has
// been calculated along with the width of the inner grid
// container below.
$outerContainerWidth: 1600px;
$innerContainerWidth: 1531px;
$innerGutterRatio: 0.315789;
$outerGutterPercentage: 2.156%;

$susy: (
	columns: $desktopColumns,
	gutters: $innerGutterRatio,
	container: $innerContainerWidth,
	container-position: center,
	gutter-position: split,
	math: fluid
);

$desktopGrid: (
	columns: $desktopColumns,
	gutters: $innerGutterRatio
);

$mobileGrid: (
	columns: $mobileColumns,
	gutters: $innerGutterRatio
);


/******************************************
 *
 * Old layout
 *
 ******************************************/

$columnNumber: 16;
$innerGutter: 1.5%;
$outerGutter: 2*$innerGutter;
$columnWidth: (100% - ($columnNumber - 1) * $innerGutter - 2 * $outerGutter) / $columnNumber;

$headerHeight: 0;
$ctaHeight: 29px;
$ctaHeightPhone: 42px;
$footerBarHeight: 33px;


/******************************************
 *
 * Typography
 *
 ******************************************/

$textShadowOnImage: 1px 1px rgba(41,40,39,0.25);


/******************************************
 *
 * Animation
 *
 ******************************************/

$transitionLength: 250ms;


/******************************************
 *
 * Loader
 *
 ******************************************/

$circleSizeSmall: 6px;
$circleSizeMedium: 12px;
$circleSizeLarge: 18px;
$circleSizeHuge: 22px;
$circleAnimationLength: 1000ms;


/******************************************
 *
 * Card style
 *
 ******************************************/
 
$cardPaddingSmall: 19px;
$cardPaddingLarge: 30px;
$cardFooterPaddingVertical: 12px;
