@import "_imports";

.uiHero {
	.heroForm {
		position: relative;
		max-width: $heroContentMaxWidth;
		margin: 60px auto 0;
		text-align: left;

		input, button {
			height: $heroControlHeight;
			margin: 0;
			border: 3px solid $primary;
			@include border-radius(0);
		}

		input {
			@include box-sizing(border-box);
			padding-left: 10px;
			padding-right: 10px;
			font-size: 24px;
			font-weight: 300;
			line-height: 28px;
			color: $primary;
		}

		& > input {
			float: left;
			width: 100% - $heroButtonWidth;

			// resize on phone to fill full width
			@include bp(phone) {
				width: auto;
			}
		}

		@include placeholder() {
			color: $primary;
			line-height: 30px;
		}

		button {
			float: right;
			width: $heroButtonWidth;
			min-width: 0;
			padding-left: 0;
			padding-right: 0;
			font-size: 18px;
			font-weight: 400;
			letter-spacing: 2px;
			background: $primary;
			color: white;

			// resize on phone so just a square button
			// bump up font size since now just an icon
			@include bp(phone) {
				width: $heroControlHeight;
				line-height: $heroControlHeight;
				font-size: 32px;
				padding: 0;
				z-index: 10;
			}
		}
	}
}

.heroBlock {
	position: relative;
	display: block;
	height: $heroHeight;
	margin-bottom: $pageMarginTop;
	width: 100%;

	&:hover {
		.imgHolder .img.gradientOverlay:after {
			opacity: 0.5;
		}
	}

	&.short {
		height: $heroHeightShort;

		@include bp(phone) {
			height: auto
		}

		// scale hero in browsers that support vw units
		@include bp(tablet) {
			height: 45vw;
		}
	}

	.heroContent {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		@include gridPaddingLeft(0, 0, 1);
		@include gridPaddingRight(0, 0, 1);
		margin-top: -(($heroTitleLineHeight*2 + $heroTitleMarginBottom + $heroControlHeight)/2) + ($headerHeight/2);
		z-index: 1;

		.buttons {
			margin-top: 40px;
		}

		// no hero unit on mobile
		@include bp(phone) {
			position: static;
			margin-top: 0;
			padding-top: $headerHeight + $pageMarginTop + 15px;
		}
	}

	.heroTitle {
		margin-bottom: $heroTitleMarginBottom;
		text-align: center;
		color: #fff;
		font-size: 50px;
		font-weight: 500;
		text-shadow: 1px 1px rgba(41,40,39,0.25);
		line-height: $heroTitleLineHeight;

		@include bp(phone) {
			margin-bottom: 25px;
			font-size: 7.2vw;
			line-height: 7.8vw;
			color: black;
			text-shadow: none;

			br {
				display: none;
			}
		}

		// scale text in browsers that support vw units
		@include bp(tablet) {
			margin-bottom: 4vw;
			font-size: 5vw;
			line-height: 5.4vw;
		}
	}

	.imgHolder {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-bottom: 1px solid white;
		overflow: hidden;
		@include placeholderBackground();

		// no hero unit on mobile
		@include bp(phone) {
			display: none;
		}

		.img {
			position: relative;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			&.gradientOverlay {
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					height: 40%;
					@include background-image(linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1)));
					@include transition(all 450ms);
					opacity: 0.3;
				}
			}

			&.darkOverlay {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: #141413;
					opacity: 0.45;
				}
			}
		}
	}

	.meta {
		position: absolute;
		left: $outerGutter;
		bottom: 40px;
		right: 0;

		@include bp(phone) {
			display: none;
		}

		h4 {
			display: block;
			max-width: 750px;
			margin: 0 0 20px;
			font-weight: 500;
			font-size: 42px;
			line-height: 48px;
			color: white;
			text-shadow: $textShadowOnImage;
		}

		a {
			color: white;
			@include transition(color $transitionLength);

			&:hover {
				color: $primary;
			}
		}

		.body {
			display: block;
			max-width: 700px;
			max-height: 78px;
			margin-right: 60px;
			overflow: hidden;
			line-height: 26px;
			font-size: 16px;
			font-weight: 400;
			color: white;
			color: rgba(255,255,255,0.8);
			text-shadow: $textShadowOnImage;

			&:before {
				content: 'by ';
				font-style: italic;
				color: rgba(255,255,255,0.7)!important;
			}
		}

		&.bodyAsCredit {
			h4 {
				margin-bottom: 10px;
			}

			.body {
				font-size: 26px;
				font-weight: 300;
			}
		}

		&.plain {
			bottom: 23px;

			.image {
				float: left;
				margin-right: 8px;
			}

			a.image {
				border: 1px solid transparent;
				@include transition(border-color $transitionLength);

				&:hover {
					border-color: $primary;
				}
			}

			img {
				display: block;
				width: 34px;
				height: 34px;
			}

			h4 {
				margin-bottom: 3px;
				font-size: 16px;
				line-height: 16px;
			}

			.body {
				font-size: 16px;
				line-height: 16px;
			}
		}
	}
}
