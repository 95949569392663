@import "_imports";

.panes {
	.pane {
		&:before {
			display: block;
			border-top: 1px solid rgba($gunmetalWeak, 0.5);
		}

		&:first-child {
			&:before {
				border-top: none;
			}
		}

		&.noBorder {
			&:before {
				border-top: none;
			}
		}

		&.tight {
			& > .rail,
			& > .content {
				padding-bottom: 30px;
			}
		}

		&.tightest {
			& > .rail,
			& > .content {
				padding: 30px 0;
			}
		}

		&.noRail {
			& > .content {
				margin-left: span($desktopRailColumns) + 3*gutter($desktopColumns);
			}
		}

		&.formHeader {
			& > .rail,
			& > .content {
				padding-top: 70px;
			}
		}
	}
}


/*
 * Desktop
 */

@include susy-breakpoint($desktop, $desktopGrid) {
	.panes {
		.pane {
			&:before {
				margin-left: gutter();
				margin-right: span(3) + 3 * gutter();
			}
			
			& > .rail,
			& > .content,
			& > .sidebar {
				padding: 35px 0 50px;

				&.tight {
					padding: 0 0 25px;
				}
			}

			& > .rail {
				@include span($desktopRailColumns of $desktopColumns);
			}

			& > .content {
				@include span($desktopMainColumns of $desktopColumns);
			}

			& > .sidebar {
				@include span($desktopSidebarColumns of $desktopColumns);

				.tooltip {
					padding: 30px 0;
					border-top: 1px solid rgba($gunmetalWeak, 0.5);
					border-top: 1px solid rgba($gunmetalWeak, 0.5);

					:first-child {
						margin-top: 0;
					}
				}
			}

			.buttons {
				.uiButton + .uiButton {
					// margin-left: 25px;
				}
			}

			.submissionButtons {
				.uiButton {
					min-width: 170px;

					button {
						width: 100%;
					}
				}
			}

			.paneForm {
				@include span(8 of $desktopMainColumns after);
				@include nested(8) {

					.controls {
						@include container();

						& > .control {
							margin-top: 0;
						}

						&.halves {
							& > :nth-child(1) { @include span(4 after); }
							& > :nth-child(2) { @include span(4 after last); }
						}
					}

					.formError {
						margin-top: 35px - $subheadArtifactHeight;
					}

					.buttons {
						margin-top: 35px;
						text-align: center;

						&:first-child {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
}


/*
 * Mobile
 */

@include susy-breakpoint($mobile, $mobileGrid) {
	.panes {
		.pane {
			&:before {
				margin-left: gutter();
				margin-right: gutter();
			}
			
			& > .rail {
				@include span(full);
				padding: 35px 0 25px;
			}

			& > .sidebar {
				display: none;
			}

			& > .content {
				@include span(full);
				padding-bottom: 50px;
			}

			&.noRail {
				& > .content {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}
}
