@import "_imports";

/**
 * Card style (product responses)
 */

.card {
	border: 1px solid rgba($gunmetalNeutral, 0.28);
	border-radius: 3px;
	background: white;

	& + .card {
		margin-top: $cardPaddingSmall;
	}

	& > .preview {
		@include display-flex;
			display: -ms-flexbox;
		@include align-items(center);
		padding: $cardPaddingSmall;

		.text {
			@include flex-grow(1);
				-ms-flex: 1 0 auto;
		}
	}

	& > .content {
		padding: ($cardPaddingLarge - $cardPaddingSmall) $cardPaddingSmall $cardPaddingSmall;

		&.expanded {
			.expandable {
				display: block;
			}
		}

		.expandable {
			display: none;
		}

		.cardRow + .cardRow,
		.expandable + .cardRow,
		.cardRow + .expandable > .cardRow:first-child {
			margin-top: $cardPaddingLarge;
		}

		.longform {
			@include hyphenation;
		}
	}

	& > .footer {
		@include display-flex;
			display: -ms-flexbox;
		@include align-items(center);
			-ms-flex-align: center;
		padding: $cardFooterPaddingVertical $cardPaddingSmall;
		background: rgba($gunmetalWeak, 0.14);

		.text {
			@include flex-grow(1);
				-ms-flex: 1 1 auto;
		}
	}

	.buttons {
		@include flex-shrink(0);
			-ms-flex: 0 0 auto;
		margin-left: $cardPaddingLarge;

		.uiButton.inline,
		a.inline {
			& + .uiButton.inline,
			& + a.inline {
				margin-left: 7px;
			}
		}
	}
}


/**
 * Styles for abstraction
 */

.expandToggle {
	$expandPadding: 5px;

	// Expand hit target
	// Body style is short a pixel at top, account for this
	// Also account for padding around glyph on right-hand side
	margin: -$expandPadding;
	padding: ($expandPadding + 1px) ($expandPadding - 2px) ($expandPadding + 1px) $expandPadding;
	border-radius: $borderRadiusSmall;
	@include transition(background, $transitionLengthShort);

	&:hover {
		background: rgba($blue, 0.14);
	}

	& > span {
		display: block;

		&:after {
			@include glyph;
			content: '1';
			position: relative;
			top: 1px;
			padding-left: 2px;
		}
	}

	&.expanded {
		& > span {
			&:after {
				content: '2';
			}
		}
	}
}
