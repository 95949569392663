.uiText,
.uiTextarea,
.uiSelect {
	&.invalid, {
		input,
		textarea,
		& > .element {
			border-bottom-color: transparent;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

.control {
	.error {
		display: none;
		position: relative;
		margin-top: -$formsElementBorderWidth;
		padding: 9px $formsElementPaddingHorizontal;
		border-left: $formsElementBorderWidth solid $formsElementBorderColorInvalid;
		border-right: $formsElementBorderWidth solid $formsElementBorderColorInvalid;
		border-bottom: $formsElementBorderWidth solid $formsElementBorderColorInvalid;
		border-radius: 0 0 $formsElementBorderRadius $formsElementBorderRadius;
		z-index: 1;
		color: $formsElementBorderColorInvalid;
		background: $red-5;

		span {
			display: block;
		}
	}

	&.invalid {
		.error {
			display: block;
		}
	}
}

.uiFormMessage {
	text-align: left;
	padding: 7px $formsElementPaddingHorizontal 5px $formsElementPaddingHorizontal;
	border-radius: $formsElementBorderRadius;
	font-size: 12px;

	&.default {
		background: rgba($gunmetalWeak, 0.1);

		.message {
			color: $weak;
		}
	}

	&.danger {
		background: $red-5;

		.message {
			color: $formsElementBorderColorInvalid;
		}
	}
}
