
.veryWeakColor { color: $veryWeak; }
.weakColor { color: $weak; }
.neutralColor { color: $neutral; }
.strongColor { color: $strong; }
.veryStrongColor { color: $veryStrong; }

.whiteVeryWeakColor { color: $whiteVeryWeak; }
.whiteWeakColor { color: $whiteWeak; }
.whiteNeutralColor { color: $whiteNeutral; }
.whiteStrongColor { color: $whiteStrong; }
.whiteVeryStrongColor { color: $whiteVeryStrong; }

.gunmetalVeryWeakColor { color: $gunmetalVeryWeak; }
.gunmetalWeakColor { color: $gunmetalWeak; }
.gunmetalNeutralColor { color: $gunmetalNeutral; }

.blue { color: $blue; }
.green { color: $green; }
.red { color: $red; }
.yellow { color: $yellow; }