
$linkUnderlineThickness: 1px;

/*
 * Base styles
 */

body,
input,
textarea {
	font-family: "TeXGyreHerosRegular", Helvetica, Arial, sans-serif !important;
	color: $strong;
	-webkit-font-smoothing: antialiased;
}

h1,
.display3 {
	@include display3()
}

h2,
.display2 {
	@include display2();
}

h3,
.display1 {
	@include display1();
}

h4,
.headline {
	@include headline();
}

h5,
.title {
	@include title();
}

h6,
.subhead {
	@include subhead();
}

.section {
	@include section();
}

p,
.body {
	@include body();
}

.caption {
	@include caption();
}

.compact {
	@include compact();
}

.tag {
	@include tag();
}



/*
 * Margins between typographic elements
 * in an interface
 */

h1,
.display3 {
	&,
	& + .module {
		& + h2,
		& + .display2 {
			margin-top: -$display2BaselineHeight + $display3LineHeight;
		}

		& + h3,
		& + .display1 {
			margin-top: -$display1BaselineHeight + $display3LineHeight;
		}

		& + h4,
		& + .headline {
			margin-top: -$headlineBaselineHeight + $display3LineHeight;
		}

		& + h5,
		& + .title {
			margin-top: -$titleBaselineHeight + $display3LineHeight;
		}

		& + h6,
		& + .subhead {
			margin-top: -$subheadBaselineHeight + $display3LineHeight;
		}

		& + .section {
			margin-top: -$sectionBaselineHeight + $display3LineHeight;
		}

		& + p,
		& + .body {
			margin-top: -$bodyBaselineHeight + $display3LineHeight;
		}

		& + .caption {
			margin-top: -$captionBaselineHeight + $display3LineHeight;
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + $display3LineHeight;
		}
	}

	& + .module {
		margin-top: $display3LineHeight;
	}
}

h2,
.display2 {
	&,
	& + .module {
		& + h3,
		& + .display1 {
			margin-top: -$display1BaselineHeight + $display2LineHeight;
		}

		& + h4,
		& + .headline {
			margin-top: -$headlineBaselineHeight + $display2LineHeight;
		}

		& + h5,
		& + .title {
			margin-top: -$titleBaselineHeight + $display2LineHeight;
		}

		& + h6,
		& + .subhead {
			margin-top: -$subheadBaselineHeight + $display2LineHeight;
		}

		& + .section {
			margin-top: -$sectionBaselineHeight + $display2LineHeight;
		}

		& + p,
		& + .body {
			margin-top: -$bodyBaselineHeight + $display2LineHeight;
		}

		& + .caption {
			margin-top: -$captionBaselineHeight + $display2LineHeight;
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + $display2LineHeight;
		}
	}

	& + .module {
		margin-top: $display2LineHeight;
	}
}

h3,
.display1 {
	&,
	& + .module {
		& + h4,
		& + .headline {
			margin-top: -$headlineBaselineHeight + $display1LineHeight;
		}

		& + h5,
		& + .title {
			margin-top: -$titleBaselineHeight + $display1LineHeight;
		}

		& + h6,
		& + .subhead {
			margin-top: -$subheadBaselineHeight + $display1LineHeight;
		}

		& + .section {
			margin-top: -$sectionBaselineHeight + $display1LineHeight;
		}

		& + p,
		& + .body {
			margin-top: -$bodyBaselineHeight + 27px;		/* Exception */
		}

		& + .caption {
			margin-top: -$captionBaselineHeight + $display1LineHeight;
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + $display1LineHeight;
		}
	}

	& + .module {
		margin-top: $display1LineHeight;
	}
}

h4,
.headline {
	&,
	& + .module {
		& + h5,
		& + .title {
			margin-top: -$titleBaselineHeight + $headlineLineHeight;
		}

		& + h6,
		& + .subhead {
			margin-top: -$subheadBaselineHeight + $headlineLineHeight;
		}

		& + .section {
			margin-top: -$sectionBaselineHeight + $headlineLineHeight;
		}

		& + p,
		& + .body {
			margin-top: -$bodyBaselineHeight + $headlineLineHeight;
		}

		& + .caption {
			margin-top: -$captionBaselineHeight + 21px;		/* Exception */
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + $headlineLineHeight;
		}
	}

	& + .module {
		margin-top: $headlineLineHeight;
	}
}

h5,
.title {
	&,
	& + .module {
		& + h6,
		& + .subhead {
			margin-top: -$subheadBaselineHeight + $titleLineHeight;
		}

		& + .section {
			margin-top: -$sectionBaselineHeight + $titleLineHeight;
		}

		& + p,
		& + .body {
			margin-top: -$bodyBaselineHeight + $titleLineHeight;
		}

		& + .caption {
			margin-top: -$captionBaselineHeight + $headlineLineHeight;
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + $headlineLineHeight;
		}
	}

	& + .module {
		margin-top: $titleLineHeight;
	}
}

h6,
.subhead {
	&,
	& + .module {
		& + .section {
			margin-top: -$sectionBaselineHeight + $subheadLineHeight;
		}

		& + p,
		& + .body {
			margin-top: -$bodyBaselineHeight + $subheadLineHeight;
		}

		& + .caption {
			margin-top: -$captionBaselineHeight + 24px;		/* Exception */
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + $subheadLineHeight;
		}
	}

	& + .module {
		margin-top: $subheadLineHeight;
	}
}

.section {
	&,
	& + .module {
		& + p,
		& + .body {
			margin-top: -$bodyBaselineHeight + $sectionLineHeight;
		}

		& + .caption {
			margin-top: -$captionBaselineHeight + $sectionLineHeight;
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + $sectionLineHeight;
		}
	}

	& + .module {
		margin-top: $sectionLineHeight;
	}
}

p,
.body {
	&,
	& + .module {
		& + .caption {
			margin-top: -$captionBaselineHeight + 20px;			/* Exception */
		}

		& + .tag {
			margin-top: -$tagBaselineHeight + 20px;					/* Exception */
		}
	}

	& + .module {
		margin-top: $bodyLineHeight;
	}
}

.caption {
	& + .tag {
		margin-top: -$tagBaselineHeight + $captionLineHeight;
	}
}


/*
 * Margins between typographic elements
 * in a longform text block, when a big
 * element follows a smaller element
 */

.longform {
	h1,
	.display3 {
		&,
		& + .module {
			& + h1,
			& + .display3 {
				margin-top: 2*($display3LineHeight - ($display3FontSize - $display3ArtifactHeight)) - $display3ArtifactHeight;
			}
		}
	}

	h2,
	.display2 {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2 {
				margin-top: 2*($display2LineHeight - ($display2FontSize - $display2ArtifactHeight)) - $display2ArtifactHeight;
			}
		}
	}

	h3,
	.display1 {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1 {
				margin-top: 2*($display1LineHeight - ($display1FontSize - $display1ArtifactHeight)) - $display1ArtifactHeight;
			}
		}
	}

	h4,
	.headline {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline {
				margin-top: 2*($headlineLineHeight - ($headlineFontSize - $headlineArtifactHeight)) - $headlineArtifactHeight;
			}
		}
	}

	h5,
	.title {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline,
			& + h5,
			& + .title {
				margin-top: 2*($titleLineHeight - ($titleFontSize - $titleArtifactHeight)) - $titleArtifactHeight;
			}
		}
	}

	h6,
	.subhead {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline,
			& + h5,
			& + .title,
			& + h6,
			& + .subhead {
				margin-top: 2*($subheadLineHeight - ($subheadFontSize - $subheadArtifactHeight)) - $subheadArtifactHeight;
			}
		}
	}

	.section {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline,
			& + h5,
			& + .title,
			& + h6,
			& + .subhead,
			& + .section {
				margin-top: 2*($sectionLineHeight - ($sectionFontSize - $sectionArtifactHeight)) - $sectionArtifactHeight;
			}
		}
	}

	.compact {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline,
			& + h5,
			& + .title,
			& + h6,
			& + .subhead,
			& + .section,
			& + .compact {
				margin-top: 2*($compactLineHeight - ($compactFontSize - $subheadArtifactHeight)) - $subheadArtifactHeight;
			}
		}
	}

	&,
	.body,
	p {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline,
			& + h5,
			& + .title,
			& + h6,
			& + .subhead,
			& + .section,
			& + .compact,
			& + p,
			& + .body {
				margin-top: 2*($bodyLineHeight - ($bodyFontSize - $bodyArtifactHeight)) - $bodyArtifactHeight;
			}
		}
	}

	.caption {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline,
			& + h5,
			& + .title,
			& + h6,
			& + .subhead,
			& + .section,
			& + .compact,
			& + p,
			& + .body,
			& + .caption {
				margin-top: 2*($captionLineHeight - ($captionFontSize - $captionArtifactHeight)) - $captionArtifactHeight;
			}
		}
	}

	.tag {
		&,
		& + .module {
			& + h1,
			& + .display3,
			& + h2,
			& + .display2,
			& + h3,
			& + .display1,
			& + h4,
			& + .headline,
			& + h5,
			& + .title,
			& + h6,
			& + .subhead,
			& + .section,
			& + .compact,
			& + p,
			& + .body,
			& + .caption,
			& + .tag {
				margin-top: 2*($tagLineHeight - ($tagFontSize - $tagArtifactHeight)) - $tagArtifactHeight;
			}
		}
	}
}


/*
 * Variants
 */

// Font weight
.light {
	font-weight: 300;
}

.regular {
	font-weight: 400;
}

strong,
.medium,
.strong {
	font-weight: 500;
}

.heavy {
	font-weight: 700;
}

// Style
em,
.emphasis {
	font-style: italic;
}

// Letter spacing
.tight {
	letter-spacing: $tightLetterSpacing;
}

.snug {
	letter-spacing: $snugLetterSpacing;
}

.loose {
	letter-spacing: $looseLetterSpacing;
}

.airy {
	letter-spacing: $airyLetterSpacing;
}

// Transforms
.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.block {
	display: block;
}

a {
	position: relative;
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	@include transition(color $transitionLengthShort);

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: $linkUnderlineThickness;
		left: 0;
		bottom: $linkUnderlineThickness;
		background: $blue;
		@include transition(all $transitionLengthShort);
	}

	&:hover {
		color: $blue;

		&:after {
			opacity: 0;
		}
	}

	&.strong {
		font-weight: 400;
		color: $blue;

		&:hover {
			color: $blue2;

			&:after {
				opacity: 1;
				background: $blue2;
			}
		}
	}

	// No styling
	&.noStyle {
		color: inherit;
		@include transform(none);

		&:after {
			content: none;
		}

		&:hover {
			color: inherit;
		}
	}

	// Block links
	&.block {
		text-decoration: none;

		&:after {
			content: none;
		}

		&:hover {
			color: $blue1;
		}

		&:active {
			color: $blue2;
		}

		// Strong
		&.strong {
			color: $blue;

			&:hover {
				color: $blue1;
			}

			&:active {
				color: $blue2;
			}
		}

		// Very strong
		&.veryStrong {
			font-weight: 500;
			color: $blue;

			&:hover {
				color: $blue1;
			}

			&:active {
				color: $blue2;
			}
		}

		// Inline
		&.inline {
			display: inline-block;
		}
	}
}

// Link color variants
.danger {
	a {
		&:after {
			background: $red;
		}

		&.strong {
			color: $red;

			&:hover {
				color: $red2;

				&:after {
					background: $red2;
				}
			}
		}
	}
}

code {
	display: inline-block;
	padding: 0 3px;
	font-family: monospace;
	color: $red;
	background: $red-5;
	border-radius: $borderRadiusSmall;
}

@mixin baseline() {
	display: block;
	height: $baselineRatio + em;
	line-height: 0.8em;
	margin-top: 0;
	margin-bottom: 0;
}

.ellipsis {
	@include ellipsis();
}

.glyph {
	@include glyph();

	&.circle {
		position: relative;
		padding-left: 0.35em;
		padding-right: 0.35em;

		&:before {
			content: '\25EF';
			position: absolute;
			left: 50%;
			font-size: 1.3em;
			@include transform(translateX(-50%));
		}
	}
}

// Byline
.by {
	font-weight: 400 !important;
	font-style: italic;
}


/*
 * Font imports
 */
@font-face {
    font-family: 'Architizer Glyphs';
    src: url('/fonts/glyphs/architizerglyphs-regular-webfont.eot');
    src: url('/fonts/glyphs/architizerglyphs-regular-webfont.eot?#iefix') format('embedded-opentype'),
            url('/fonts/glyphs/architizerglyphs-regular-webfont.woff') format('woff'),
            url('/fonts/glyphs/architizerglyphs-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

//@font-face {
//    font-family: 'Theinhardt-web';
//    src: url('/fonts/theinhardt/theinhardtlight_alt-webfont.eot');
//    src: url('/fonts/theinhardt/theinhardtlight_alt-webfont.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/theinhardt/theinhardtlight_alt-webfont.woff') format('woff'),
//         url('/fonts/theinhardt/theinhardtlight_alt-webfont.ttf') format('truetype');
//    font-weight: 300;
//    font-style: normal;
//}
//
//@font-face {
//    font-family: 'Theinhardt-web';
//    src: url('/fonts/theinhardt/theinhardtreg-webfont.eot');
//    src: url('/fonts/theinhardt/theinhardtreg-webfont.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/theinhardt/theinhardtreg-webfont.woff') format('woff'),
//         url('/fonts/theinhardt/theinhardtreg-webfont.ttf') format('truetype');
//    font-weight: 400;
//    font-style: normal;
//}
//@font-face {
//    font-family: 'Theinhardt-web';
//    src: url('/fonts/theinhardt/theinhardtregit-webfont.eot');
//    src: url('/fonts/theinhardt/theinhardtregit-webfont.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/theinhardt/theinhardtregit-webfont.woff') format('woff'),
//         url('/fonts/theinhardt/theinhardtregit-webfont.ttf') format('truetype');
//    font-weight: 400;
//    font-style: italic;
//}
//
//
//@font-face {
//    font-family: 'Theinhardt-web';
//    src: url('/fonts/theinhardt/theinhardtmed-webfont.eot');
//    src: url('/fonts/theinhardt/theinhardtmed-webfont.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/theinhardt/theinhardtmed-webfont.woff') format('woff'),
//         url('/fonts/theinhardt/theinhardtmed-webfont.ttf') format('truetype');
//    font-weight: 500;
//    font-style: normal;
//}
//
//@font-face {
//    font-family: 'Theinhardt-web';
//    src: url('/fonts/theinhardt/theinhardtheavy-webfont.eot');
//    src: url('/fonts/theinhardt/theinhardtheavy-webfont.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/theinhardt/theinhardtheavy-webfont.woff') format('woff'),
//         url('/fonts/theinhardt/theinhardtheavy-webfont.ttf') format('truetype');
//    font-weight: 700;
//    font-style: normal;
//}
