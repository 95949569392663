
@import "_imports";
$pageTransitionLength: 0.12s;
$pageLoaderTransitionLength: 0.3s;
$pageLoaderHeight: 45px;
$pageLoaderWidth: 50px;

@-webkit-keyframes loaderEntry {
	0%			{ opacity: 0; }
	90%			{ opacity: 0; }
	100% 		{ opacity: 1; }
}

@keyframes loaderEntry {
	0%			{ opacity: 0; }
	90%			{ opacity: 0; }
	100% 		{ opacity: 1; }
}

#content {
	// When in the loading state, hide all ui-views
	&.loading {
		opacity: 0 !important;
	}
}

#footer {

}

#pageLoader {
	position: fixed;
	left: 50%;
	top: 50%;
	width: $pageLoaderWidth;
	height: $pageLoaderHeight;
	z-index: 4;
	margin-left: -($pageLoaderWidth/2);
	margin-top: -($pageLoaderHeight/2);

	// Showing the placeholder
	&.ng-hide-remove {
		opacity: 0;

		&.ng-hide-remove-active {
			opacity: 1;
		}
	}

	// Hiding the placeholder
	&.ng-hide-add {
		&.ng-hide-add-active {
			opacity: 0;
		}
	}	
}

#content.ng-leave-active + #pageLoader {
	opacity: 0;
}