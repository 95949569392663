@import "_imports";

$formElementMargin: 15px;
$formElementMarginLarge: 25px;

.formHolder {
	max-width: 760px;
	margin: 0 auto;
	padding: 0 $outerGutter;
	font-size: 16px;
}

.form {

	.formGroup {
		clear: left;
		@include clearfix();

		.half {
			float: left;
			width: 49%;
		}

		.half + .half {
			margin-left: 2%;
			clear: none;
		}
	}

	.formGroup + .formGroup,
	.formHeader + .formGroup {
		margin-top: $formElementMargin;
	}

	.formHeader {
		margin-top: $formElementMarginLarge;
		font-weight: 500;
	}

	.muted {
		color: #aaa;
	}

	.button.submit {
		width: 100%;
		margin-top: 40px;
	}
}