@import "_imports";

.hide {
	visibility: hidden;
}

.max {
	@include bp(mobile) { display: none !important; }
	@include bp(full) { display: none !important; }
}

.desktop {
	@include bp(mobile) { display: none !important; }
}

.mobile {
	@include bp(desktop) { display: none !important; }
}

.tablet {
	@include bp(phone) { display: none !important; }
	@include bp(desktop) { display: none !important; }
}

.phone {
	@include bp(notPhone) { display: none !important; }
}

.hideWhenDesktop {
	@include bp(desktop) { display: none !important; }
}

.hideWhenMobile {
	@include bp(mobile) { display: none !important; }
}

.hideWhenTablet {
	@include bp(tablet) { display: none !important; }
}

.hideWhenPhone {
	@include bp(phone) { display: none !important; }
}

.hideWhenSmallPhone {
	@include breakpoint($smallPhone) { display: none !important; }
}

.pullLeft {
	float: left;
}

.pullRight {
	float: right;
}

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}