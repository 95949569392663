@import "_imports";

$sidebarBackgroundPhone: rgba(255,255,255,0.95);

.catalogSidebar {
	padding-bottom: 50px;
	margin-top: -100px;
	padding-top: 100px;

	margin-top: -($headerHeight + $pageMarginTop);
	padding-top: $headerHeight + $pageMarginTop;

	.ctaVisible & {
		margin-top: -($headerHeight + $pageMarginTop + $ctaHeight);
		padding-top: $headerHeight + $pageMarginTop + $ctaHeight;
	}

	@include bp(mobile) {
		position: fixed;
		top: $headerHeight + 20px;
		bottom: 20px;
		right: 0;
		width: 80%;
		margin-top: 0;
		margin-left: 0 !important;
		margin-right: 0 !important;
		padding: 10px;
		float: none !important;
		z-index: 10;
		background: $sidebarBackgroundPhone;
		@include transition(transform 500ms);
		@include transform(translateX(100%) !important);

		form {
			height: 100%;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}

		.callout {
			position: absolute;
			top: 0;
			right: 100%;
			width: 36px;
			height: 24px;
			padding: 10px 8px 10px 8px;
			box-sizing: initial;
			text-align: center;
			background: $sidebarBackgroundPhone;
			-webkit-tap-highlight-color: rgba(0,0,0,0);

			&:before {
				content: "y";
				display: inline-block;
				font-size: 24px;
				line-height: 1;
				cursor: pointer;
				color: #c2c0be;
				@include glyphs();
				@include transition(transform 500ms);
			}

			&:active {
				background: #0cb7fa;

				&:before {
					color: #fff;
				}
			}
		}

		&.open {
			@include transform(translateX(0) !important);

			.callout {
				&:before {
					@include transform(rotate(180deg));
				}
			}
		}
	}

	& > .content {
		height: 500px;
		background: #dedede;
	}
}