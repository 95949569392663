.icon {
  font-family: 'Architizer Glyphs';
  font-weight: normal;
  text-transform: none;
}
.icon > span {
  display: none;
}
.icon.facebook:before {
  content: 'f';
}
.icon.twitter:before {
  content: 'g';
}
.icon.pinterest:before {
  content: 'k';
}
.icon.linkedin:before {
  content: 'i';
}
.icon.google:before {
  content: 'G';
}
.icon.tumblr:before {
  content: 'h';
}
.icon.instagram:before {
  content: 'j';
}
.icon.send:before {
  content: 'e';
}
.icon.send:before {
  content: 'M';
}
.icon.mail:before {
  content: 'l';
}
.icon.phone:before {
  content: '@';
}
.icon.info:before {
  content: '&';
}
.icon.collection:before {
  content: 'u';
}
.icon.user:before {
  content: '$';
}
.icon.followers:before {
  content: '<';
}
.icon.following:before {
  content: '>';
}
.icon.upload:before {
  content: 'c';
}
.icon.global:before {
  content: 'W';
}
.icon.work:before {
  content: 'E';
}
.icon.conversation:before {
  content: 'd';
}
.icon.tagged:before {
  content: 'D';
}
.icon.plus:before {
  content: 's';
}
.icon.magnify:before {
  content: 'S';
}
.icon.marker:before {
  content: 'L';
}
.icon.edit:before {
  content: 'p';
}
.icon.view:before {
  content: 'v';
}
.icon.commerce:before {
  content: 'T';
}
.icon.up:before {
  content: 'w';
}
.icon.down:before {
  content: 'x';
}
.icon.previous:before {
  content: 'y';
}
.icon.next:before {
  content: 'z';
}
.icon.list:before {
  content: 'm';
}
.icon.bell:before {
  content: 'a';
}
.icon.heart:before {
  content: 'b';
}
.icon.favorites:before {
  content: '*';
}
.icon.trash:before {
  content: 'V';
}
.icon.trash:before {
  content: 'V';
}
.icon.check:before {
  content: 'q';
}
.icon.read:before {
  content: 'F';
}
.icon.stats:before {
  content: 'H';
}
.icon.expand:before {
  content: 'I';
}
.icon.contract:before {
  content: 'J';
}
.icon.world:before {
  content: 'K';
}
.icon.send:before {
  content: 'e';
}
.icon.send:before {
  content: 'M';
}
.icon.link:before {
  content: 'N';
}
.icon.attachment:before {
  content: 'O';
}
.icon.home:before {
  content: 'P';
}
.icon.flag:before {
  content: 'Q';
}
.icon.calendar:before {
  content: 'R';
}
.icon.time:before {
  content: 'U';
}
.icon.trash:before {
  content: 'V';
}
.icon.trash:before {
  content: 'V';
}
.icon.cart:before {
  content: 'X';
}
.icon.bldg:before {
  content: 'Y';
}
.icon.product:before {
  content: '[';
}
.icon.settings:before {
  content: 'o';
}
.icon.cancel:before,
.icon.exit:before {
  content: 'r';
}
.icon.lock:before {
  content: '(';
}
.icon.key:before {
  content: ')';
}