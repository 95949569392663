
$white: #ffffff;
$black: #000000;

$weakest: rgba($black, 0.20);
$veryWeak: rgba($black, 0.34);
$weak: rgba($black, 0.44);
$neutral: rgba($black, 0.64);
$strong: rgba($black, 0.84);
$veryStrong: rgba($black, 0.90);

$whiteWeakest: rgba($white, 0.20);
$whiteVeryWeak: rgba($white, 0.34);
$whiteWeak: rgba($white, 0.44);
$whiteNeutral: rgba($white, 0.64);
$whiteStrong: rgba($white, 0.86);
$whiteVeryStrong: rgba($white, 0.90);

$gunmetalVeryWeak: #eef1f2;
$gunmetalWeak: #B8C8D1;
$gunmetalNeutral: #aeb7bd;
$gunmetalStrong: #a0a8ad;
$gunmetalVeryStrong: #a0a8ad;

$titanium: #859DAF;
$titanium1: shade($titanium, 10);
$titanium2: shade($titanium, 20);

$blue: #1CA3FC;
$blue-5: tint($blue, 90);
$blue-4: tint($blue, 70);
$blue-3: tint($blue, 50);
$blue-2: tint($blue, 30);
$blue-1: tint($blue, 10);
$blue1: shade($blue, 10);
$blue2: shade($blue, 20);
$blue3: shade($blue, 30);
$blue4: shade($blue, 40);

$red: #FF4B1F;
$red-5: tint($red, 90);
$red-4: tint($red, 70);
$red-3: tint($red, 50);
$red-2: tint($red, 30);
$red-1: tint($red, 10);
$red1: shade($red, 10);
$red2: shade($red, 20);
$red3: shade($red, 30);
$red4: shade($red, 40);

$yellow: #FFCD00;
$yellow1: shade($yellow, 10);
$yellow2: shade($yellow, 20);

$green: #22D290;
$green-4: tint($green, 70);
$green-3: tint($green, 50);
$green-2: tint($green, 30);
$green-1: tint($green, 10);
$green1: shade($green, 10);
$green2: shade($green, 20);
$green3: shade($green, 30);
$green4: shade($green, 40);

$borderVeryWeak: #e9e9e9;
$borderWeak: #dddddd;
$borderNeutral: #d0d0d0;
$borderStrong: #cccccc;
$borderVeryStrong: #b2b2b2;