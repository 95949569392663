// Textarea input
.uiTextarea {
	position: relative;
	padding-top: $formsLabelHeight;

	textarea {
		@include box-sizing(border-box);
		display: block;
		width: 100%;
		min-height: 2*$formsElementHeight;
		padding: ($formsElementPaddingTop + 4px) $formsElementPaddingHorizontal ($formsElementPaddingTop + ($formsElementLineHeight + 4px));
		border: $formsElementBorderWidth solid $formsElementBorderColor;
		border-radius: $formsElementBorderRadius;
		font-size: $formsElementFontSize;
		font-weight: $formsElementFontWeight;
		line-height: $formsElementLineHeight;
		color: $formsElementColor;
		background: $white;
		letter-spacing: $formsElementLetterSpacing;
		cursor: text;
		z-index: 2;
		overflow: hidden;
		resize: none;
		@include transition(border $formsTransitionLength);

		&:hover {
			border-color: $formsElementBorderColorHover;
		}
	}

	textarea[disabled] {
		pointer-events: none;
		background: none;
	}

	&.focus {
		textarea {
			border-color: $formsElementBorderColorActive;
			outline: none;
		}
	}

	&.invalid {
		textarea {
			border-color: $formsElementBorderColorInvalid;
		}
	}

	&.noLabel,
	.noLabel & {
		padding-top: 0;
	}
}