
$actionBarHeight: 45px;
$actionBarButtonSpacing: 7px;

.uiActionBarContainer {
  @include clearfix;
}

.uiActionBar {
  @include box-sizing(border-box);
  height: $actionBarHeight;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid rgba($gunmetalWeak, 0.30);
  background: rgba($white, 0.96);
  white-space: nowrap;

  &.uiActionBarDocked {
    position: fixed;
  }

  .uiActionBarBack,
  .uiActionBarNavigation {
    @include body(); 
    margin-top: 0;
    margin-bottom: 0;
    padding-top: ($actionBarHeight - $bodyBaselineHeight)/2 - ($bodyAscenderHeight/2);
    padding-bottom: ($actionBarHeight - $bodyBaselineHeight)/2 - $bodyDescenderHeight + ($bodyAscenderHeight/2);
  }

  .uiActionBarBack {
    position: relative;
    padding-left: 19px;

    &:before {
      content: '4';
      position: absolute;
      left: -6px;
      top: 50%;
      line-height: inherit;
      @include title();
      @include glyph();
      @include transform(translateY(-7px));
      font-weight: 700;
    }
  }

  .uiActionBarContextSelector {
    margin-right: $actionBarHeight/2;
    min-width: 0;

    // Override ui-menu-select styles
    &.uiMenuSelect {
      &,
      &.hasValue {
        span {
          @include title();
          font-weight: 500;
          color: $strong;

          &:after {
            top: -2px;
          }
        }

        &.open {
          span {
            &:after {
              top: -3px;
            }
          }
        }
      } 
    }
  }

  .uiActionBarTitle {
    margin-top: 0;
    margin-bottom: 0;
    @include align-self(center);
  }

  .uiActionBarNavigation {
    padding-left: $actionBarHeight/2;
    padding-right: $actionBarHeight/2;
    color: $blue;
    @include transition(none);

    &:after {
      content: none;
    }

    &:hover {
      background: rgba($gunmetalWeak, 0.2);
    }

    &:active {
      background: rgba($gunmetalWeak, 0.15);
    }

    &.active {
      background: rgba($gunmetalWeak, 0.15);
      color: $blue;
    }

    .notificationBadge {
      position: relative;
      top: -1px;
      margin-left: 10px;
    }
  }

  .uiActionBarControl,
  .uiActionBarButton {
    margin-top: 0 !important;
  }

  .uiActionBarControl {
    @include flex-basis(100%);
    @include flex-shrink(1);
    max-width: 200px;

    & + .uiActionBarControl,
    &.pullRight + .uiActionBarControl.pullRight {
      margin-left: $actionBarButtonSpacing;
    }

    // Ensure controls shrink to available space
    // flex-shrink won't work if child content has a set width 
    input {
      width: 100%;
    }
  }

  .uiButton {
    button.default {
      background: white
    }
  }

  .marginLeft {
    margin-left: $actionBarButtonSpacing;
  }

  .pullRight {
    margin-left: auto;

    & + .pullRight {
      margin-left: 0;

      &.uiButton {
        margin-left: $actionBarButtonSpacing;
      }
    }
  }

  .container {
    height: 100%;

    .inner {
      @include display-flex;
      display: -ms-flexbox;
      @include align-items(center);
      height: 100%;
    }
  }
}

@include susy-breakpoint($desktop, $desktopGrid) {
  .uiActionBar {
    .container {
      .inner {
        @include span(full);
      }

      .uiActionBarBack {
        width: span($desktopRailColumns);
        margin-right: 2*gutter();
      }
    }
  }
}

@include susy-breakpoint($mobile, $mobileGrid) {
  .uiActionBar {
    .container {
      .inner {
        @include span(full);
      }

      .uiActionBarBack {
        margin-right: 20px;
      }
    }
  }
}
