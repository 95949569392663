@import "_imports";

// image hover tint effect
.tintOnHover {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: black;
		opacity: 0;
		@include transition(opacity 250ms);
	}

	&:hover:after {
		opacity: 0.10;
	}
}

// preloading
.hasPreload {
	opacity: 0;
	@include transition(opacity 250ms);

	&.preloaded {
		opacity: 1;
	}
}