@import "_imports";

$imageModalGutterDesktop: gutter($desktopColumns after);
$imageModalGutterMobile: 2*gutter($desktopColumns after);

.arcImage {
	.container {
		margin-left: auto;
		margin-right: auto;
	}

	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;
	background: rgba(black, 0.5);
	cursor: pointer;
	@include box-sizing(border-box);

	.modal {
		@include display-flex;
		@include flex-direction(column);
		@include box-sizing(border-box);
		@include span(full);
		cursor: default;
		background: white;

		.scroll {
			width: 100%;
			height: 660px;
			overflow: scroll;
			-webkit-overflow-scrolling: touch;

			&:after {
				content: '';
				display: block;
				height: 25px;
			}
		}

		.header {
			@include clearfix;
			padding: 20px 0;

			h4 {
				float: left;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.back {
				text-align: right;
			}
		}

		.image {
			@include box-sizing(border-box);
			position: relative;
			background: rgba($gunmetalWeak, 0.1);

			img {
				position: absolute;
				max-width: 100%;
			}
		}

		.actionsArea {
			margin: (25px - $subheadArtifactHeight) $imageModalGutterDesktop (-$subheadDescenderHeight);

			.action {
				display: inline-block;

				& + .action {
					margin-left: 15px;
				}
			}
		}

		.metaArea {
			margin: (25px - $bodyArtifactHeight) $imageModalGutterDesktop (-$bodyDescenderHeight);

			&.border {
				border-bottom: 1px solid rgba($gunmetalWeak, 0.5);
			}
		}

		.adHolder {
			margin: 25px $imageModalGutterDesktop 0;
			@include stripeBackground();
		}

		.content {
			.commentArea {
				margin-top: 40px;
				margin-bottom: 35px;
			}
		}
	}
}

/*
 * Floating style
 */

@include bp(notPhone) {
	.arcImage {
		.modal {
			overflow: hidden;
			@include border-radius(2px);

			.header {
				h4 {
					@include box-sizing(border-box);
					width: span(14 after) + gutter($desktopColumns after) - $imageModalGutterDesktop;
					padding-left: $imageModalGutterDesktop;
				}

				.back {
					@include span(2 after last);
					@include box-sizing(border-box);
					padding-right: $imageModalGutterDesktop;
				}
			}

			.content {
				.commentArea {

				}
			}
		}
	}
}


/*
 * Desktop styles
 */

@include bp(desktop) {
	.arcImage {
		.modal {
			.metaArea {
				.captionArea {
					width: span(9 after);
				}
			}

			.content {
				.commentArea {
					width: span(10 after);
					padding-left: span(3 after) + gutter($desktopColumns after);
				}
			}
		}
	}
}


/*
 * Tablet styles
 */

@include bp(tablet) {
	.arcImage {
		.modal {
			.content {
				.commentArea {
					padding-left: $imageModalGutterDesktop;
					padding-right: $imageModalGutterDesktop;
				}
			}
		}
	}
}



/*
 * Phone styles
 */

@include bp(phone) {
	.arcImage {
		padding-top: 0 !important;
		background: white;

		.modal {
			.header {
				h4 {
					@include span(13 after);
				}

				.back {
					@include span(3 after last);
				}
			}

			.uiScrollDot {
				margin-right: -20px;
				padding-right: 19px;
			}

			.content {
				.commentArea {
				}
			}
		}
	}
}
