
$heroHeight: 415px;

.uiHero {
	position: relative;
	display: block;
	height: $heroHeight;
	z-index: 3;

	.contentHolder {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: $heroHeight;
		background: rgba(black, 0.14);

		.container {
			height: $heroHeight;

			.inner {
				@include span(full);
				position: relative;
				display: table;
				height: 100%;
				z-index: 10;

				& > .wrapper {
					display: table-cell;
					vertical-align: middle;

					.content {
						text-align: center;
						color: $white;
					}
				}

				.uiAttribution {
					position: absolute;
					bottom: $marginLargest;
					left: 0;
				}

				.buttons {
					margin-top: 40px;
					text-align: center;

					.uiButton {
						min-width: 190px;

						button {
							width: 100%;
						}
					}
				}
			}
		}
	}

	.uiImage {
 		position: absolute;
 		left: 0;
 		top: 0;
 		width: 100%;
 		height: $heroHeight;
 		border-bottom: 1px solid white;
 		overflow: hidden;
 		@include placeholderBackground();

 		.imageContainer {
			height: 100%;
		 }

 		.image {
 			height: 100%;
 		}
	}
}


/**
 * Small phones styles
 */

@include breakpoint($smallPhone) {
	.uiHero {
		height: auto;

		.contentHolder {
			position: static;
			height: auto;
			background: none;

			.container {
				height: auto;
				padding-top: 40px;

				.inner {
					& > .wrapper {
						.content {
							text-align: left;
							color: $strong;

							.headline {
								color: $neutral;
							}
						}
					}

					.buttons {
						text-align: left;

						.uiButton {
							width: 100%;
							min-width: initial;
						}
					}
				}
			}
		}

		.uiImage {
			display: none;
		}
	}
}
