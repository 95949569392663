
$nativeScrollbarWidth: 12px;

$scrollbarWidth: 8px;
$scrollbarPadding: 3px;

.uiScrollDot {
	@include box-sizing(border-box);
	position: relative;

	div.scroll {
		// Pull moz scrollbar under our scrollbar
		margin-right: -$nativeScrollbarWidth;
		padding-right: $nativeScrollbarWidth;

		// Hide IE scrollbar
		-ms-overflow-style: none;

		// Hide webkit scrollbar
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.scrollBackground,
	.scrollDot {
		display: none;
	}

	.scrollBackground {
		content: '';
		position: absolute;
		top: 0;
		height: 100%;
		right: 0;
		width: $scrollbarWidth + 2*$scrollbarPadding;
		z-index: 901;
		background: white;
	}

	.scrollDot {
		position: absolute;
		top: $scrollbarPadding;
		right: $scrollbarPadding;
		width: $scrollbarWidth;
		height: 40px;
		z-index: 902;
		border-bottom: 1px solid shade($blue, 20);
		border-radius: 3px;
		background: $blue;
	}

	&.hasScroll {
		padding-right: $scrollbarWidth + 2*$scrollbarPadding;

		.scrollBackground,
		.scrollDot {
			display: block;
		}
	}
}
