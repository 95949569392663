
$tooltipMaxWidth: 220px;
$tooltipPadding: 8px;
$tooltipOffset: 13px;
$tooltipCalloutSize: 8px;
$tooltipBackground: rgba(black, 0.7);

.uiTooltip {
	position: absolute;
	max-width: $tooltipMaxWidth;
	background: $tooltipBackground;
	z-index: 10000000;

	.content {
		padding: $tooltipPadding;
		color: white;
		-webkit-font-smoothing: antialiased;
	}

	.caption {
		margin-top: -2px;
		margin-bottom: -4px;
	}

	&:before {
		content: '';
		position: absolute;
	}

	&.down {
		margin-top: $tooltipOffset;

		&:before {
			left: 50%;
			bottom: 100%;
			margin-left: -$tooltipCalloutSize;
			border-bottom: $tooltipCalloutSize solid $tooltipBackground;
			border-left: $tooltipCalloutSize solid transparent;
			border-right: $tooltipCalloutSize solid transparent;
		}
	}

	&.up {
		margin-top: -$tooltipOffset;

		&:before {
			left: 50%;
			top: 100%;
			margin-left: -$tooltipCalloutSize;
			border-top: $tooltipCalloutSize solid $tooltipBackground;
			border-left: $tooltipCalloutSize solid transparent;
			border-right: $tooltipCalloutSize solid transparent;
		}
	}
}