
/*
 * Typographic styles
 */

$display3FontSize: 60px;
$display3FontWeight: 400;
$display3LetterSpacing: 10;
$display3LineHeight: 90px;

$display2FontSize: 48px;
$display2FontWeight: 400;
$display2LetterSpacing: 10;
$display2LineHeight: 71px;

$display1FontSize: 34px;
$display1FontWeight: 400;
$display1LetterSpacing: 10;
$display1LineHeight: 50px;

$headlineFontSize: 24px;
$headlineFontWeight: 400;
$headlineLetterSpacing: 10;
$headlineLineHeight: 36px;

$titleFontSize: 20px;
$titleFontWeight: 400;
$titleLetterSpacing: 16;
$titleLineHeight: 29px;

$subheadFontSize: 16px;
$subheadFontWeight: 400;
$subheadLetterSpacing: 10;
$subheadLineHeight: 24px;

$sectionFontSize: 13px;
$sectionFontWeight: 400;
$sectionLetterSpacing: 120;
$sectionLineHeight: 17px;

$bodyFontSize: 14px;
$bodyFontWeight: 400;
$bodyLetterSpacing: 10;
$bodyLineHeight: 20px;

$captionFontSize: 12px;
$captionFontWeight: 400;
$captionLetterSpacing: 10;
$captionLineHeight: 18px;

$compactFontSize: 10px;
$compactFontWeight: 400;
$compactLetterSpacing: 75;
$compactLineHeight: 12px;

$tagFontSize: 10px;
$tagFontWeight: 400;
$tagLetterSpacing: 10;
$tagLineHeight: 10px;


/*
 * Specific to this typeface
 */

// Normalised ratios (line-height = font-size)
$artifactRatio: 0.11;									// ratio of typeface top artifact to font height
$ascenderRatio: 0.22;									// ratio of ascender height to font height
$descenderRatio: 0.20;								// ratio of descender height to font height
$baselineRatio: 1 - $descenderRatio;	// ratio of baseline to ascender height to font height

// Adjust for line-height to font-size ratio
$display3ArtifactRatio: $artifactRatio * ($display3LineHeight/$display3FontSize) * ($display3LineHeight/$display3FontSize);
$display3AscenderRatio: $ascenderRatio / ($display3LineHeight/$display3FontSize);
$display3DescenderRatio: $descenderRatio * ($display3LineHeight/$display3FontSize);
$display3BaselineRatio: 1 - $display3DescenderRatio;

$display2ArtifactRatio: $artifactRatio * ($display2LineHeight/$display2FontSize) * ($display2LineHeight/$display2FontSize);
$display2AscenderRatio: $ascenderRatio / ($display2LineHeight/$display2FontSize);
$display2DescenderRatio: $descenderRatio * ($display2LineHeight/$display2FontSize);
$display2BaselineRatio: 1 - $display2DescenderRatio;

$display1ArtifactRatio: $artifactRatio * ($display1LineHeight/$display1FontSize) * ($display1LineHeight/$display1FontSize);
$display1AscenderRatio: $ascenderRatio / ($display1LineHeight/$display1FontSize);
$display1DescenderRatio: $descenderRatio * ($display1LineHeight/$display1FontSize);
$display1BaselineRatio: 1 - $display1DescenderRatio;

$headlineArtifactRatio: $artifactRatio * ($headlineLineHeight/$headlineFontSize) * ($headlineLineHeight/$headlineFontSize);
$headlineAscenderRatio: $ascenderRatio / ($headlineLineHeight/$headlineFontSize);
$headlineDescenderRatio: $descenderRatio * ($headlineLineHeight/$headlineFontSize);
$headlineBaselineRatio: 1 - $headlineDescenderRatio;

$titleArtifactRatio: $artifactRatio * ($titleLineHeight/$titleFontSize) * ($titleLineHeight/$titleFontSize);
$titleAscenderRatio: $ascenderRatio / ($titleLineHeight/$titleFontSize);
$titleDescenderRatio: $descenderRatio * ($titleLineHeight/$titleFontSize);
$titleBaselineRatio: 1 - $titleDescenderRatio;

$headlineArtifactRatio: $artifactRatio * ($headlineLineHeight/$headlineFontSize) * ($headlineLineHeight/$headlineFontSize);
$headlineAscenderRatio: $ascenderRatio / ($headlineLineHeight/$headlineFontSize);
$headlineDescenderRatio: $descenderRatio * ($headlineLineHeight/$headlineFontSize);
$headlineBaselineRatio: 1 - $headlineDescenderRatio;

$subheadArtifactRatio: $artifactRatio * ($subheadLineHeight/$subheadFontSize) * ($subheadLineHeight/$subheadFontSize);
$subheadAscenderRatio: $ascenderRatio / ($subheadLineHeight/$subheadFontSize);
$subheadDescenderRatio: $descenderRatio * ($subheadLineHeight/$subheadFontSize);
$subheadBaselineRatio: 1 - $subheadDescenderRatio;

$sectionArtifactRatio: $artifactRatio * ($sectionLineHeight/$sectionFontSize) * ($sectionLineHeight/$sectionFontSize);
$sectionAscenderRatio: $ascenderRatio / ($sectionLineHeight/$sectionFontSize);
$sectionDescenderRatio: $descenderRatio * ($sectionLineHeight/$sectionFontSize);
$sectionBaselineRatio: 1 - $sectionDescenderRatio;

$bodyArtifactRatio: $artifactRatio * ($bodyLineHeight/$bodyFontSize) * ($bodyLineHeight/$bodyFontSize);
$bodyAscenderRatio: $ascenderRatio / ($bodyLineHeight/$bodyFontSize);
$bodyDescenderRatio: $descenderRatio * ($bodyLineHeight/$bodyFontSize);
$bodyBaselineRatio: 1 - $bodyDescenderRatio;

$captionArtifactRatio: $artifactRatio * ($captionLineHeight/$captionFontSize) * ($captionLineHeight/$captionFontSize);
$captionAscenderRatio: $ascenderRatio / ($captionLineHeight/$captionFontSize);
$captionDescenderRatio: $descenderRatio * ($captionLineHeight/$captionFontSize);
$captionBaselineRatio: 1 - $captionDescenderRatio;

$compactArtifactRatio: $artifactRatio * ($compactLineHeight/$compactFontSize) * ($compactLineHeight/$compactFontSize);
$compactAscenderRatio: $ascenderRatio / ($compactLineHeight/$compactFontSize);
$compactDescenderRatio: $descenderRatio * ($compactLineHeight/$compactFontSize);
$compactBaselineRatio: 1 - $compactDescenderRatio;

$tagArtifactRatio: $artifactRatio * ($tagLineHeight/$tagFontSize) * ($tagLineHeight/$tagFontSize);
$tagAscenderRatio: $ascenderRatio / ($tagLineHeight/$tagFontSize);
$tagDescenderRatio: $descenderRatio * ($tagLineHeight/$tagFontSize);
$tagBaselineRatio: 1 - $tagDescenderRatio;


/*
 * Typography style metrics
 */

$display3ArtifactHeight: $display3ArtifactRatio * $display3LineHeight;
$display3AscenderHeight: $display3AscenderRatio * $display3LineHeight;
$display3DescenderHeight: $display3DescenderRatio * $display3LineHeight;
$display3BaselineHeight: $display3BaselineRatio * $display3LineHeight;

$display2ArtifactHeight: $display2ArtifactRatio * $display2LineHeight;
$display2AscenderHeight: $display2AscenderRatio * $display2LineHeight;
$display2DescenderHeight: $display2DescenderRatio * $display2LineHeight;
$display2BaselineHeight: $display2BaselineRatio * $display2LineHeight;

$display1ArtifactHeight: $display1ArtifactRatio * $display1LineHeight;
$display1AscenderHeight: $display1AscenderRatio * $display1LineHeight;
$display1DescenderHeight: $display1DescenderRatio * $display1LineHeight;
$display1BaselineHeight: $display1BaselineRatio * $display1LineHeight;

$headlineArtifactHeight: $headlineArtifactRatio * $headlineLineHeight;
$headlineAscenderHeight: $headlineAscenderRatio * $headlineLineHeight;
$headlineDescenderHeight: $headlineDescenderRatio * $headlineLineHeight;
$headlineBaselineHeight: $headlineBaselineRatio * $headlineLineHeight;

$titleArtifactHeight: $titleArtifactRatio * $titleLineHeight;
$titleAscenderHeight: $titleAscenderRatio * $titleLineHeight;
$titleDescenderHeight: $titleDescenderRatio * $titleLineHeight;
$titleBaselineHeight: $titleBaselineRatio * $titleLineHeight;

$subheadArtifactHeight: $subheadArtifactRatio * $subheadLineHeight;
$subheadAscenderHeight: $subheadAscenderRatio * $subheadLineHeight;
$subheadDescenderHeight: $subheadDescenderRatio * $subheadLineHeight;
$subheadBaselineHeight: $subheadBaselineRatio * $subheadLineHeight;

$sectionArtifactHeight: $sectionArtifactRatio * $sectionLineHeight;
$sectionAscenderHeight: $sectionAscenderRatio * $sectionLineHeight;
$sectionDescenderHeight: $sectionDescenderRatio * $sectionLineHeight;
$sectionBaselineHeight: $sectionBaselineRatio * $sectionLineHeight;

$bodyArtifactHeight: $bodyArtifactRatio * $bodyLineHeight;
$bodyAscenderHeight: $bodyAscenderRatio * $bodyLineHeight;
$bodyDescenderHeight: $bodyDescenderRatio * $bodyLineHeight;
$bodyBaselineHeight: $bodyBaselineRatio * $bodyLineHeight;

$captionArtifactHeight: $captionArtifactRatio * $captionLineHeight - 1px;
$captionAscenderHeight: $captionAscenderRatio * $captionLineHeight;
$captionDescenderHeight: $captionDescenderRatio * $captionLineHeight;
$captionBaselineHeight: $captionBaselineRatio * $captionLineHeight;

$compactArtifactHeight: $compactArtifactRatio * $compactLineHeight;
$compactAscenderHeight: $compactAscenderRatio * $compactLineHeight;
$compactDescenderHeight: $compactDescenderRatio * $compactLineHeight;
$compactBaselineHeight: $compactBaselineRatio * $compactLineHeight;

$tagArtifactHeight: $tagArtifactRatio * $tagLineHeight;
$tagAscenderHeight: $tagAscenderRatio * $tagLineHeight;
$tagDescenderHeight: $tagDescenderRatio * $tagLineHeight;
$tagBaselineHeight: $tagBaselineRatio * $tagLineHeight;


/*
 * Variants
 */

$tightLetterSpacing: 10 / 1000 * 1em;
$snugLetterSpacing: 20 / 1000 * 1em;
$looseLetterSpacing: 60 / 1000 * 1em;
$airyLetterSpacing: 100 / 1000 * 1em;
