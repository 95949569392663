@import "_imports";

.container {
	@include responsive-container;
}

.span.full {
	@include susy-breakpoint($mobile, $mobileGrid) {
		@include span(full);
	}

	@include susy-breakpoint($desktop, $desktopGrid) {
		@include span(full);
	}
}

.span.main {
	@include susy-breakpoint($mobile, $mobileGrid) {
		@include span(full);
	}

	@include susy-breakpoint($desktop, $desktopGrid) {
		@include span(($desktopMainColumns + $desktopRailColumns) of $desktopColumns);
	}
}

.span.sidebar {
	@include susy-breakpoint($mobile, $mobileGrid) {
		@include span(full);
	}

	@include susy-breakpoint($desktop, $desktopGrid) {
		@include span($desktopSidebarColumns of $desktopColumns last);
	}
}
