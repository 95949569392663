@import "_imports";

$listPadding: 20px;
$listPaddingPhone: 7px;
$listLogoSize: 33px;
$listBackground: rgba($gunmetalWeak, 0.1);
$listThumbListWidth: 100px;
$listThumbListWidthVw: 7.3vw;
$listThumbListHeight: 73px;
$listThumbListHeightVw: 5.2vw;
$listThumbListMarginInner: 10px;
$listThumbListMarginOuter: 18px;
$listThumbListMarginPhone: 5px;
$listFontSize: 14px;

.swatchList {
	min-height: 1px;

	.listBlock {
		position: relative;
		display: block;
		margin-bottom: 24px;
		padding: $listPadding;
		overflow: auto;
		background: $listBackground;

		.top {
			.logo {
				float: left;
				width: $listLogoSize;
				height: $listLogoSize;
				margin-right: 18px;
				margin-top: $titleArtifactHeight;
				border: none;
				background: #363534;
			}
			
			.name {
				@include bp(phone) {
					height: auto;
				}
				
				@include bp(notPhone) {
					a {
						display: inline-block;
					}
				}

				.verified {
					$badgeSize: 14px;

					@include bp(notPhone) {
						display: inline-block;
						margin-left: 5px;
						vertical-align: middle;
						
						.glyph {
							top: 2px;
						}

						.caption {
							display: none;
						}
					}

					@include bp(phone) {
						display: block;
						height: $badgeSize;
						line-height: $badgeSize;
						margin-top: 7px;
						@include clearfix();

						.glyph {
							display: inline-block;
							top: -1px;
							height: $badgeSize;
							margin-right: 3px;
							vertical-align: middle;
						}

						.caption {
							display: inline-block;
							vertical-align: middle;
						}
					}

					.glyph {
						font-size: 32px;
						text-transform: none;
					}
				}
			}

			.meta {
				position: relative;
				display: block;
				margin-top: 7px;

				@include bp(phone) {
					clear: left;
				}
				
				a {
					display: inline-block;
				}
			}

			&.hasLogo {
				.meta {
					@include bp(phone) {
						padding-top: 7px;
					}
				}
			}

			&.notPaid {
				.title {
					.text {
						display: block;
					}
				}
			}

			&.hasLogo.notPaid {
				.title {
					.text {
						line-height: $listLogoSize;
						margin-bottom: -10px;
					}
				}
			}
		}

		.note {
			a {
				display: inline-block;
			}
		}

		.thumbList {
			height: $listThumbListHeight;
			margin: $listThumbListMarginOuter 0;
			overflow: hidden;
			white-space: nowrap;

			li {
				display: inline-block;

				@include bp(notPhone) {
					margin-left: $listThumbListMarginInner;
				}

				@include bp(phone) {
					margin-left: $listThumbListMarginPhone;	
				}

				&:first-child {
					margin-left: 0;
				}
			}

			.thumb {
				display: block;
				height: 0;
				width: $listThumbListWidth;
				padding-top: $listThumbListHeight;
				overflow: hidden;
				background-position: center;
				background-repeat: none;
				background-size: cover;
				background-color: #dedede;
			}

			@include bp(max) {
				height: $listThumbListHeightVw;

				.thumb {
					width: $listThumbListWidthVw;
					padding-top: $listThumbListHeightVw;
				}
			}
		}
	}

	// load more results button
	.loadMoreWrapper {
		@include gridMarginLeft(0,0,1);

		.loadMore {
			display: block;
			margin-top: 36px;
			text-align: center;

			button {
				position: relative;
				
				&.progress {
					.loader {
						opacity: 1;
					}

					span {
						opacity: 0
					}
				}

				span,
				.loader {
					@include transition(opacity 150ms);
				}

				.loader {
					position: absolute;
					left: 50%;
					top: 50%;
					margin-left: -2*$circleSizeMedium;
					margin-top: -$circleSizeMedium/2;
					opacity: 0;
				}

				span {
					opacity: 1;
				}
			}	
		}
	}
}

// progress animation
@keyframes buttonProgress {
	from { transform: rotate(0); }
	to { transform: rotate(360deg); }
}

@-webkit-keyframes buttonProgress {
	from { -webkit-transform: rotate(0); }
	to { -webkit-transform: rotate(360deg); }
}