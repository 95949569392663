
$flashPadding: 12px;
$flashBackground: rgba(black, 0.84);
$flashIndicatorWidth: 6px;
$flashMaxHeight: 52px;

@keyframes flashEntry {
	0% { 
		max-height: 0;
		margin-top: 0;
		animation-timing-function: ease-out;
	}
	100% {
		max-height: $flashMaxHeight;
		margin-top: 12px;
	}
}

@keyframes flashEntryInner {
	0% { 
		@include transform(translateY(40px));
		animation-timing-function: ease-out;
	}
	100% {
		@include transform(translateY(0));
	}
}

@-webkit-keyframes flashEntry {
	0% { 
		max-height: 0;
		margin-top: 0;
		animation-timing-function: ease-out;
	}
	100% {
		max-height: $flashMaxHeight;
		margin-top: 12px;
	}
}

@-webkit-keyframes flashEntryInner {
	0% { 
		@include transform(translateY(40px));
		animation-timing-function: ease-out;
	}
	100% {
		@include transform(translateY(0));
	}
}

@keyframes flashExit {
	0% { 
		opacity: 1; 
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes flashExit {
	0% { 
		opacity: 1; 
	}
	100% {
		opacity: 0;
	}
}

.uiFlashContainer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 20000;
	margin-bottom: 25px;

	.uiFlash {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		
		// Entry/exit
		&.entering {
			animation: flashEntry $transitionLengthMedium;
			-webkit-animation: flashEntry $transitionLengthMedium;

			.inner {
				animation: flashEntryInner $transitionLengthMedium;
				-webkit-animation: flashEntryInner $transitionLengthMedium;
			}
		}

		&.exiting {
			animation: flashExit $transitionLengthMedium;
			-webkit-animation: flashExit $transitionLengthMedium;
		}

		// Colors
		&.error {
			.inner:before {
				border-left-color: $red;
			}
		}

		&.warning {
			.inner:before {
				border-left-color: $yellow;
			}
		}

		&.success {
			.inner:before {
				border-left-color: $green;
			}
		}

		.inner {
			position: relative;
			padding: $flashPadding $flashPadding ($flashPadding - 4px);
			line-height: 18px;
			text-align: center;
			background: $flashBackground;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				border-left: $flashIndicatorWidth solid transparent;
			}
		}
	}

	.uiFlash + .uiFlash {
		margin-top: 12px;
	}
}