@import "_imports";
$headerUnit-height: 510px;
$headerUnit-height-tablet: 420px;
$headerUnit-height-mobile: 310px;
div.contentUnit hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid $gunmetalWeak;
		padding: 0;
		width: 90%;
}
div.container.lightUnit {
		background: #fff;
		margin: auto;
		padding: 100px 0;
}
.page#team {
		.contentUnit {
				.textUnit {
						max-width: span(12);
						margin: 45px auto 0;
						& p {
								max-width: span(14);
								margin: 30px auto 0;
						}
						& .image {
								margin: 60px auto auto;
								width: 100%;
								height: auto;
								&.mobileImage {
										display: none;
								}
						}
						& .uiButton {
								margin: 30px auto;
						}
						& a.block {
								margin: 30px 0 0;
						}
				}
				&.headerUnit {
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center 20%;
						color: $white;
						height: $headerUnit-height;
						padding: 0;
						position: relative;
						& .container {
								position: relative;
								top: 50%;
								transform: translateY(-50%);
								text-align: center;
								& h2.twentyfour {
										font-size: 24px;
										margin: 0;
										line-height: 70px;
								}
								& .image {
										height: 40px;
								}
						}
						& .attribution {
								text-align: right;
								position: absolute;
								bottom: 30px;
								right: 30px;
						}
				}
		}
}
div#ourteam {
		padding: 0;
		& .container .testimonials {
				// div {
				// 	width: span(4);
				// }
				width: span(14);
				margin: auto auto 50px;
				// 	margin: auto auto 50px auto;
		}
}
ul.teamlist {
		max-width: span(15);
		margin: auto;
    margin-bottom: 100px;
		& li {
				display: inline-block;
				width: span(3.5);
				padding: 15px;
				vertical-align: top;
				margin-bottom: 40px;
				& img.image {
						border-radius: 999px;
						width: 120px;
						height: 120px;
						margin-bottom: 15px;
				}
		}
}

/*
 * Tablet styles
 */

@include susy-breakpoint($tablet, $mobileGrid) {
		ul.teamlist {
				max-width: span(8);
				margin: auto;
        margin-bottom: 50px;
				& li {
            & img.image {
    						width: 100px;
    						height: 100px;
    				}
				}
		}
}


/*
 * Phone styles
 */

@include susy-breakpoint($phone, $mobileGrid) {
		div .textUnit {
				width: 100%;
		}
		.uiHero.tallest div.small {
				color: $white;
		}
}
@include susy-breakpoint($largePhone, $mobileGrid) {
		ul.teamlist {
				max-width: span(8);
				margin: auto;
        margin-bottom: 40px;
				& li {
						display: inline-block;
						width: span(3);
						padding: 20px;
            & img.image {
    						width: 100px;
    						height: 100px;
    				}
				}
		}
}
@include susy-breakpoint($smallPhone, $mobileGrid) {
		ul.teamlist {
				max-width: span(8);
				margin: auto;
				text-align: center;
				& li {
						display: inline-block;
						text-align: center;
						margin: auto;
						width: 100%;
						padding: 0 0 50px 0;
            & img.image {
    						width: 100px;
    						height: 100px;
								margin-bottom: 0px;
    				}
				}
		}
		.page#team .contentUnit.headerUnit .container h2.twentyfour {
				line-height: 30px;
				margin: 20px 0;
		}
		.page#team {
				.contentUnit {
						.textUnit {
								max-width: span(8);
								text-align: center;
						}
				}
		}
}
