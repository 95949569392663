
@import "_imports";

$marketingPanePadding: 80px;
$marketingPanePaddingTight: 50px;

$bobDistance: 5px;
@keyframes bob {
	0% { @include transform(translateY(0)); }
	60% { @include transform(translateY(0)); }
	70% { @include transform(translateY($bobDistance)); }
	80% { @include transform(translateY(0)); }
	90% { @include transform(translateY($bobDistance)); }
	100% { @include transform(translateY(0)); }
}

@-webkit-keyframes bob {
	0% { @include transform(translateY(0)); }
	60% { @include transform(translateY(0)); }
	70% { @include transform(translateY($bobDistance)); }
	80% { @include transform(translateY(0)); }
	90% { @include transform(translateY($bobDistance)); }
	100% { @include transform(translateY(0)); }
}

#sourceMarketingSuppliers {
	.pane:last-child {
		padding-bottom: 200px;
	}
}


/**
 * Styles for abstraction
 */



.page {

	// Fix for width issues on IE10
	display: block;

	.contentUnit {
		@include clearfix;
		text-align: center;
		padding: $marketingPanePadding 0;

		&.tight {
			padding: $marketingPanePaddingTight 0;
		}

		&,
		&.light {
			background: white;
		}

		&.shade {
			background: rgba($gunmetalWeak, 0.1);
		}

		&.dark {
			background: #292827;
			color: $whiteStrong;
		}

		&.image {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			color: $whiteStrong;
			.display1,
			.display2,
			.display3 {
				color: $white;
			}
		}

		.buttons {
			margin-top: 40px;
			.uiButton {
				min-width: 190px;
				button {
					width: 100%;
				}
			}
		}
	}

	.display1.underline {
		position: relative;
		padding-bottom: 40px - $display1DescenderHeight;
		margin-bottom: 75px;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 25%;
			right: 25%;
			border-bottom: 1px solid rgba($gunmetalWeak, 0.5);
		}
	}

	.module {
		@include display-flex;
		@include clearfix();
		clear: left;

		& > div {
			@include display-flex();
			@include flex-direction(column);
			@include justify-content(center);
			height: 286px;
			text-align: left;
		}

		.image {
			max-width: 275px;
			width: 100%;
			height: auto;
		}
	}

	.testimonials {
		.image {
			display: block;
			width: 92px;
			height: 92px;
			margin: 0 auto 30px;
			border-radius: 46px;
		}

		.body {
			margin-top: 30px - $bodyArtifactHeight;
		}
	}

}


/*
 * Desktop styles
 */

@include susy-breakpoint($desktop, $desktopGrid) {
	.page {
		.contentUnit {
			&.underlayFooter {
				$unitPaddingBottom: 200px;

				padding-bottom: $unitPaddingBottom + $footerHeightDesktop;
				margin-bottom: -$footerHeightDesktop;
			}
		}

		.module {
			& > div {
				@include span(6);

				&:first-child {
					margin-left: span(2) + 3*gutter();
				}

				&:nth-child(2) {
					margin-left: gutter();
				}

				&.small {
					width: span(4);
				}

				&.large {
					width: span(8);
				}
			}
		}

		.testimonials {
			& > div {
				@include flex-basis(0);
				@include flex-grow(1);
					-ms-flex: 1 1 0px;

				& + div {
					margin-left: 6%;
				}
			}
		}
	}
}


/*
 * Tablet styles
 */

@include susy-breakpoint($tablet, $mobileGrid) {
	.page {
		.contentUnit {
			&.underlayFooter {
				$unitPaddingBottom: 200px;

				padding-bottom: $unitPaddingBottom + $footerHeightMobile;
				margin-bottom: -$footerHeightMobile;
			}
		}

		.module {
			& > div {
				@include span(4);

				&.small {
					width: span(3);
				}

				&.large {
					width: span(5);
				}
			}
		}

		.testimonials {
			@include flex-wrap(wrap);
				-ms-flex-wrap: wrap;
			@include justify-content(center);
				-ms-flex-pack: justify;

			& > div {
				width: 47%;
				margin-top: 75px;

				&:nth-child(2n) {
					margin-left: 6%;
				}

				&:nth-child(1),
				&:nth-child(2) {
					margin-top: 0;
				}
			}
		}
	}
}


/*
 * Common to desktops and tablets
 */

@include breakpoint($notPhone) {
	.page {
		.module {
			& + .module {
				margin-top: 40px;
			}

			& > div {
				&:first-child {
					.image {
						margin-right: auto;
					}
				}

				&:nth-child(2) {
					.image {
						margin-left: auto;
					}
				}
			}
		}

		.testimonials {
			@include display-flex();
				display: -ms-flexbox;
			@include flex-direction(row);
				-ms-flex-direction: row;
		}
	}
}


/*
 * Phone styles
 */

@include susy-breakpoint($phone, $mobileGrid) {
	.page {
		.module {
			@include flex-direction(column);
				-ms-flex-direction: column;

			& + .module {
					margin-top: 75px;
			}

			& > div {
				@include span(full);
				height: auto;

				&.small {
					@include order(1);
					margin-bottom: 40px;

					img {
							max-width: 200px;
					}
				}

				&.large {
					@include order(2);
				}
			}
		}
	}

	.testimonials {
		& > div {
			& + div {
				margin-top: 75px;
			}
		}
	}
}


/**
 * Large phone styles
 */

@include breakpoint($largePhone) {
	.page {
		.contentUnit {
			&.underlayFooter {
				$unitPaddingBottom: 200px;

				padding-bottom: $unitPaddingBottom + $footerHeightMobile;
				margin-bottom: -$footerHeightMobile;
			}
		}

		.display1.underline {
			&:after {
				left: 15%;
				right: 15%;
			}
		}

		.testimonials {
			& > div {
				max-width: 390px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}


/**
 * Teeny-tiny phone styles
 */

@include breakpoint($smallPhone) {
	.page {
		.contentUnit {
			text-align: left;

			&.image {
				background: none !important;

				&,
				.display1,
				.display2,
				.display3 {
					color: $strong;
				}
			}

			.display1.underline {
				margin-bottom: 40px;

				&:after {
					left: 0;
					right: 0;
				}
			}

			.buttons {
				.uiButton {
					width: 100%;
				}
			}
		}

		.testimonials {
			.image {
				margin-left: 0;
			}
		}
	}
}
