
$formsFileDropAreaWidth: 340px;
$formsFileDropAreaHeight: 193px;
$formsFileBorderRadius: 3px;

$formsFileMaskHintOffset: 90px;

@keyframes fileHintEntry {
	0% { 
		@include transform(translateX(-50%) translateY($formsFileMaskHintOffset));
		animation-timing-function: ease-out;
	}
	100% {
		@include transform(translateX(-50%) translateY(0));
	}
}

.uiFile {
	width: $formsFileDropAreaWidth;

	.fileList {
		width: 100%;

		li {
			$iconAndMarginWidth: 23px;

			list-style: none;
			margin-bottom: $marginLarger;

			.glyph {
				float: left;
				font-size: 18px;
				line-height: 11px;
			}

			.fileName {
				display: block;
				margin-left: $iconAndMarginWidth;
			}

			.fileAction {
				float: right;
			}

			.errorMessage {
				display: block;
				clear: both;
				margin-top: 9px;
				margin-left: $iconAndMarginWidth;
				margin-right: 20px;
			}
		}
	}

	.dropAreaContainer {
		position: relative;
		width: 100%;
		height: $formsFileDropAreaHeight;

		.dropAreaBackground {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border: 1px solid rgba($gunmetalWeak, 0.3);
			border-radius: $formsFileBorderRadius;
			background: white;
			@include transition(all $transitionLengthShort);
		}

		.dropAreaProgress {
			@include display-flex;
				display: -ms-flexbox;
			@include flex-direction(column);
			@include align-items(center);
			@include justify-content(center);	
				
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 1px;
			border-radius: $formsFileBorderRadius;
			opacity: 0;
			@include transition(all $transitionLengthShort);

			.uploadProgress,
			.queueProgress {
				margin-top: 10px - $bodyArtifactHeight;
				text-align: center;
			}
			
			.uploadProgress {
				color: white;
			}
			
			.queueProgress {
				color: $whiteNeutral;
			}
		}

		.dropAreaPreview {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 1px;
			border-radius: $formsFileBorderRadius;
			background-size: cover;
			background-repeat: none;
			background-position: center;
			opacity: 0;
			@include transition(all $transitionLengthShort);
			
			.uiFileTray {
				z-index: 1;
			}
			
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: rgba(black, 0.5);
			}
		}

		.dropArea {
			@include display-flex;
				display: -ms-flexbox;
			@include align-items(center);
			@include justify-content(center);
				
			position: absolute;
			left: 1px;
			top: 1px;
			width: 100%;
			height: 100%;
			@include box-sizing(border-box);
			text-align: center;
			color: $neutral;
			cursor: pointer;
			@include transition(all $transitionLengthShort);

			.labelWrapper {
				.label {
					.glyph {
						display: block;
						font-size: 36px;
						line-height: 24px;
						margin-bottom: 12px;
						margin-top: 14px;
						color: $gunmetalWeak;
						@include transition(color $transitionLengthShort);
					}

					.uiButton {
						margin-top: 15px;
					}
				}
			}
		}

		.clickElement {
			position: absolute;
			left: 1px;
			top: 1px;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
		}

		&:hover {
			.dropAreaBackground {
				border-color: rgba($gunmetalNeutral, 0.3);
			}
		}

		&:active {
			.dropAreaBackground {
				border-color: rgba($gunmetalStrong, 0.3);
			}
		}

		&.hasPreview {
			.dropAreaPreview {
				opacity: 1;
			}

			.dropAreaProgress {
				opacity: 1;
				z-index: 2;
			}

			.dropArea {
				opacity: 0;
			}
		}

		&.uploading {
			.dropArea {
				opacity: 0;
			}

			.dropAreaProgress {
				opacity: 1;
			}
		}
	}
}

.uiFileMask {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	background: rgba($blue, 0.1);
	
	.uiFileMaskHint {
		position: absolute;
		bottom: $formsFileMaskHintOffset;
		left: 50%;
		@include transform(translateX(-50%));
		padding: 12px;
		background: $blue;
		color: white;
		border-radius: 3px;
		pointer-events: none;
		
		animation: fileHintEntry $transitionLengthMedium;
			-webkit-animation: fileHintEntry $transitionLengthMedium;
	}
}

// Place uiFileTray styles outside of uiFile so that
// code in attachment previews can use it also
.uiFileTray {
	@include display-flex;
		display: -ms-flexbox;
		
	position: absolute;
	top: 4px;
	right: 4px;
	z-index: 10;
	
	& > * {
		margin-left: 4px;
		
		&:first-child {
			margin-left: 0;
		}
	}
	
	.badge {
		padding: 7px;
		color: white;
		border-radius: 2px;
		background: rgba(black, 0.5);
	}
	
	.uiButton {
		button {
			height: 21px;
			border-radius: 2px;
			
			&.hasIcon:not(.hasLabel) {
				width: 21px;
				
				.icon {
					font-size: 13px;
				}	
			}
		}
	}
}