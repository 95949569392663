@import "_imports";

/* Custom styles for Marketing Pages */
/* Old - to be deprecated */

$hero-height: 480px;
$padded: 100px;
$logo-width: 350px;

.outlineButton {
	&:after {
		background: none;
	}
	& p {
		display: inline-block;
		margin: auto;
		color: $white;
		border-radius: 3px;
		text-decoration: none;
		padding: 6px 30px;
		border: 1px solid $white;
		border-radius: 2px;
		transition-property: color, background-color;
		transition-duration: .2s;
		transition-timing-function: ease-in-out;

		& span.glyph {
			color: white;
			font-weight: bold;
			transition-property: color, background-color;
			transition-duration: .2s;
			transition-timing-function: ease-in-out;
		}
		&:hover {
			background-color: $white;
			color: $blue;

			& span.glyph {
				color: $blue;
			}
		}
	}
}

.filledButton {
	@extend .outlineButton;
	background: $white;
	color: $blue;
	margin-right: 10px;
	& p {
		background: $white;
		color: $blue;
	}
}

.page {
	.contentUnit {
		&.headerBlue {
			// background: linear-gradient(45deg, $blue, #0C81FA);
			background: url('/images/source/marketing/suppliers-header-bg-2015.jpg') no-repeat ;
			background-position: 0 36%;
			background-size: cover;
			color: $white;
		}
		&.backgrounded {
			background: url('/images/source/marketing/suppliers-footer-bg-2015.jpg') no-repeat center;
			background-size: cover;
			color: #fff;
			padding: 125px 0;
			.buttons {
				margin-bottom: 20px;
			}
			& .image {
				width: 50%;
				max-width: $logo-width;
				margin-bottom: 40px;
			}
		}
	}
	.testimonials {
		padding-top: 20px;
		text-align: center;

		& p.padded {
			padding-top: 27px;
		}
		& .image.centered {
			margin: auto;
			margin-bottom: 20px;
		}
	}
}
.display2.left, p.headline.left {
	text-align: left;
}
.uiHero.taller {
	height: $hero-height;

	& .uiImage, .contentHolder {
		margin: auto;
		height: $hero-height;
		background: rgba(0, 0, 0, .14);

		& .container {
			height: $hero-height;
		}
	}
	& .uiImage .imageContainer .image.cropped {
		background-position: center 35% !important;
	}
	& div.small {
		max-width: 85%;
		margin: auto;
		text-align: center;

		& img.image {
			margin-bottom: 40px;
			width: 50%;
			max-width: $logo-width;
		}
	}
}
h3.padded {
	margin-bottom: 40px;
}
@include susy-breakpoint($desktop, $desktopGrid) {
	.testimonials {
		padding: 0 50px 0 50px;
	}
}
@include susy-breakpoint($phone, $mobileGrid) {
	.page .module>div.small img {
		margin:auto;
	}
	.uiHero.taller {
		height: $hero-height;
		background: url('/images/source/marketing/suppliers-header-bg-2015.jpg') no-repeat top center;
		background-size: cover;
		text-align: center;

		& .uiImage, .contentHolder {
			background: rgba(0, 0, 0, .14);

			& .container {
				& .inner>.wrapper .content .headline {
					color: $white;
					text-align: center;
				}
			}
		}
		& div.small {
			max-width: 100%;

			& img.image {
				max-width: 200px;
			}
		}
	}
}

/**
* Large phone styles
*/
@include breakpoint($largePhone) {
	div.small {
		max-width: 100%;

		& img.image {
			width: 200px;
		}
	}
}

/**
* Teeny-tiny phone styles
*/
@include breakpoint($smallPhone) {
	div.small {
		max-width: 100%;
		margin: auto;

		& img.image {
			width: 200px;
		}
	}
}

/* End Custom Styles */
