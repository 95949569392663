@import "_imports";

$settingsLogoSize: 70px;
$settingsLogoMargin: 24px;
$settingsMenuHeight: 45px;

$settingsMenuUnderline: 2px;
$settingsMenuBackgroundColor: $gunmetalVeryWeak;
$settingsMenuLineHeight: $headerHeight + $settingsMenuUnderline;

$actionBarHeight: 29px;	/* height of a medium button */
$actionBarPadding: 25px;
$actionBarMargin: 30px;

.page.settings {

	// The header of the brand
	& > .header {
		padding: 35px 0;

		.logo {
			float: left;
			width: $settingsLogoSize;
			height: $settingsLogoSize;
			margin-right: $settingsLogoMargin;

			.uiPlaceholder {
				height: 100%;
			}

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.text {
			display: block;
			
			h2,
			.types,
			a.caption {
				margin-left: $settingsLogoSize + $settingsLogoMargin;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.types {
				margin-top: 12px;

				.type {
					display: inline-block;
				}

				.type:not(:first-child) {
					@include dividerBefore();	
				}
			}
			
			a.caption {
				margin-top: 15px - $captionArtifactHeight;
				
				.glyph {
					margin-left: 3px;
				}
			}
		}
	}

	// The menu bar
	.tran {
		top: 140px;

		&.scrollfix { 
			left: 0;
			width: 100%;
			z-index: 10;

			.scrollFixOnly {
				display: block;
			}
		}

		.scrollFixOnly {
			display: none;
		}

		.left {
			.logo {
				float: left;
				display: block;
				width: $settingsMenuHeight;
				height: $settingsMenuHeight;
			}

			.brandName,
			.firmName {
				float: left;
				display: inline-block;
				height: $settingsMenuHeight;
				line-height: $settingsMenuHeight;
				margin-left: 24px;
				margin-right: 75px;

				.subhead {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		.items {
			float: left;

			.item {
				display: inline-block;

				a {
					position: relative;
					display: block;
					height: $settingsMenuHeight;
					line-height: $settingsMenuHeight;
					color: $strong;

					span {
						display: inline-block;
						vertical-align: middle;
					}

					&.active {
						&:after {
							content: '';
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							height: 0;
							border-bottom: 4px solid $gunmetalWeak;
						}

						&:hover {
							&:after {
								opacity: 1;
							}
						}
					}
				}
			}

			.item + .item {
				margin-left: 70px;
			}
		}

		.action {
			height: $settingsMenuHeight;
			line-height: $settingsMenuHeight;
			text-align: right;

			a {
				display: inline-block;
				vertical-align: middle;

				.glyph {
					margin-left: 0.5em
				}
			}
		}
	}

	.subpage {
		padding: 80px 0 30px;

		.actionBar {
			margin-top: -($actionBarHeight + $actionBarPadding);
			margin-bottom: $actionBarMargin;
			padding-bottom: $actionBarPadding;
			border-bottom: 1px solid $gunmetalWeak;

			.middleRight {
				text-align: right;
				
				.uiLinkButton {
					margin-bottom: -4px;
				}
			}

			.right {
				text-align: right;
			}
		}

		.panes {
			.pane:first-child {
				& > .rail,
				& > .content {
					border-top: 0;
				}
			}
		}

		.form {
			.plainText {
				margin: 50px 0 10px;

				&:first-child {
					margin-top: 0;
				}
			}

			.buttons {
				height: auto;
				margin-top: 50px;
				padding-top: 25px;
				border-top: 1px solid $borderWeak;
				text-align: center;
			}
		}
	}

	#pageUsers {
		$userImageSize: 50px;
		$userImageMargin: 17px;

		.users {
			& > .user {
				@include clearfix();
				padding: 15px 0;
				border-top: 1px solid $borderVeryWeak;

				&:first-child {
					border-top: 0;
				}

				.image {
					float: left;
					width: $userImageSize;
					height: $userImageSize;
					margin-right: $userImageMargin;
				}

				.pullRight {
					line-height: $userImageSize;
				}

				.buttons {
					display: inline-block;
					vertical-align: middle;
					line-height: normal;

					.uiButton {
						float: left;
					}

					.uiButton + .uiButton {
						margin-left: 5px;
					}
				}
			}
		}

		#pendingRequests,
		#pendingInvitations,
		#teamMembers {
			.content {
				padding-top: 4px;
			}

			&:first-child {
				& > .rail,
				& > .content {
					margin-top: -$actionBarMargin;
				}
			}
			
			a {
				font-weight: 500;
			}
		}

		#pendingRequests {
			.user {
				.pullLeft {
					line-height: $userImageSize;
				}

				.name {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		#pendingInvitations,
		#teamMembers {
			.pullLeft {
				white-space: nowrap;
			}

			.text {

				.name {
					margin-top: 4px;
				}

				.position {
					margin-top: 8px;
				}

				.location {
					margin-top: 6px;

					span {
						display: inline-block;
					}
				}
			}
		}
	}
}


/*
 * Desktop styles
 */
 
@include susy-breakpoint($desktop, $desktopGrid) {
	.page.settings {
		.tran {
			.left {
				@include span(($desktopColumns - 3));
			}
			
			.action {
				@include span(3 last);
			}
		}
		
		.subpage {
			.actionBar {
				height: $actionBarHeight;
				
				@include container();
				@include span($desktopRailColumns + $desktopMainColumns);
				@include nested($desktopRailColumns + $desktopMainColumns) {
					.left,
					.middle,
					.middleLeft,
					.middleRight,
					.right {
						height: $actionBarHeight;
						line-height: $actionBarHeight;
						
		
						& > * {
							display: inline-block;
							vertical-align: bottom;
						}
					}
			
					.left {
						@include span($desktopRailColumns after);
						
						.glyph {
							position: absolute;
							left: -16px;
							top: -1px;
						}
					}
					
					.middle {
						@include span(($desktopMainColumns - 2) after);
					}
	
					.middleLeft {
						@include span(($desktopMainColumns - 5) after);	
					}
					
					.middleRight {
						@include span(3 after);
					}
					
					.right {
						@include span(2 after last);
					}
				}
			}
		}
		
		.form {
			@include span($desktopMainColumns at ($desktopRailColumns+1) isolate);
			@include nested($desktopMainColumns) {
				.control {
					max-width: span(($desktopMainColumns - 2) after);
				}
			}
		}
	}
}


/*
 * Mobile styles
 */
 
@include susy-breakpoint($mobile, $mobileGrid) {
	.page.settings {
		.tran {
			.left {
				@include span(full);
			}	
		}
		
		.subpage {
			.actionBar {
				position: relative;
				@include span(full);
				
				.left {
					.glyph {
						margin-right: 4px;
					}
				}
				
				.middleLeft {
					margin-top: 30px;
				}
				
				.middleLeft + .middleRight,
				.middleLeft + .right {
					position: absolute;
					top: -$bodyArtifactHeight;
					right: 0;
				}
			}
		}
		
		.form {
			@include span(full);
		}
		
		.users {
			.user {
				@include container;
				@include nested($mobileColumns) {
					.pullLeft {
						@include span(6 after);
					}
					
					.pullRight {
						@include span(2 after last);
						text-align: right;
					}
				}	
			}
		}
	}
}

/*
 * Tablet styles
 */
 
@include susy-breakpoint($tablet, $mobileGrid) {
	.page.settings {
		.form {
			width: span($mobileColumns - 2);
			margin-left: span(1) + 3*gutter();
		}
	}
}


/*
 * Not phone styles
 */
 
@include breakpoint($notPhone) {
	.page.settings {
		& > .header {
			.text {
				margin-top: 14px;
			}
		}

		.subpage {
			.form {
				.buttons {
					.uiButton {
						margin-left: 15px;
						margin-top: 0;
	
						&:first-child {
							margin-left: 0;
						}
	
						button {
							display: block;
							min-width: 165px;
						}
					}
				}
			}
		}
		
		#pageUsers {
			#pendingInvitations,
			#teamMembers {
				.text {
					.location {
						.regions {
							@include dividerBefore();
						}
					}
				}
			}
		}		
	}
}


/*
 * Phone styles
 */
 
@include susy-breakpoint($phone, $mobileGrid) {
	.page.settings {
		.tran {
			&.scrollfix {
				position: absolute;
				top: 140px;
				
				.logo,
				.brandName {
					display: none;
				}
			}	
		}
		
		.subpage {
			.actionBar {
				.right {
					text-align: center;
				}	
			}
			
			.form {
				.buttons {
					.uiButton {
						display: block;
						margin-left: 0;
						margin-top: 25px;
	
						&:first-child {
							margin-top: 20px;
						}
	
						button {
							display: block;
							width: 100%;
						}
					}
				}
			}
		}
		
		.users {
			.user {
				.metaItem {
					display: none;
					
					&:first-child { 
						display: block; 
					}
				}
			}	
		}
		
		#pageUsers {
			#pendingInvitations,
			#teamMembers {
				.text {
					.location {
						.country {
							display: none;
						}
						
						.regions {
							display: block;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
	}
}


// #adkFooter {
// 	position:absolute;
// 	bottom: 0;
// 	width: 100%;
// }
