
@mixin photoshopTrackingToCSS($tracking) {
	letter-spacing: $tracking/1000*1em;
}

@mixin glyph() {
	font-family: "Architizer Glyphs" !important;
	font-weight: normal;
	text-transform: none;
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin display3 {
	font-size: $display3FontSize;
	line-height: $display3LineHeight;
	margin-top: -$display3ArtifactHeight;
	margin-bottom: -$display3DescenderHeight;
}

@mixin display2 {
	font-size: $display2FontSize;
	line-height: $display2LineHeight;
	margin-top: -$display2ArtifactHeight;
	margin-bottom: -$display2DescenderHeight;
}

@mixin display1 {
	font-size: $display1FontSize;
	line-height: $display1LineHeight;
	margin-top: -$display1ArtifactHeight;
	margin-bottom: -$display1DescenderHeight;
}

@mixin headline {
	font-size: $headlineFontSize;
	line-height: $headlineLineHeight;
	margin-top: -$headlineArtifactHeight;
	margin-bottom: -$headlineDescenderHeight;
}

@mixin title {
	font-size: $titleFontSize;
	line-height: $titleLineHeight;
	margin-top: -$titleArtifactHeight;
	margin-bottom: -$titleDescenderHeight;
}

@mixin subhead {
	font-size: $subheadFontSize;
	line-height: $subheadLineHeight;
	margin-top: -$subheadArtifactHeight;
	margin-bottom: -$subheadDescenderHeight;
}

@mixin section {
	font-size: $sectionFontSize;
	line-height: $sectionLineHeight;
	text-transform: uppercase;
	margin-top: -$sectionArtifactHeight;
	margin-bottom: -$sectionDescenderHeight;
}

@mixin body {
	font-size: $bodyFontSize;
	line-height: $bodyLineHeight;
	margin-top: -$bodyArtifactHeight;
	margin-bottom: -$bodyDescenderHeight;
}

@mixin caption {
	font-size: $captionFontSize;
	line-height: $captionLineHeight;
	margin-top: -$captionArtifactHeight;
	margin-bottom: -$captionDescenderHeight;
}

@mixin compact {
	font-size: $compactFontSize;
	line-height: $compactLineHeight;
	text-transform: uppercase;
	margin-top: -$ascenderRatio * $compactLineHeight;
	margin-bottom: -$descenderRatio * $compactLineHeight;
}

@mixin tag {
	font-size: $tagFontSize;
	line-height: $tagLineHeight;
	margin-top: -$tagArtifactHeight;
	margin-bottom: -$tagDescenderHeight;
}
