
@keyframes maskEntry {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes maskEntry {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes maskExit {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes maskExit {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.uiMask {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.75);
	animation: maskEntry 0.36s;
		-webkit-animation: maskEntry 0.36s;

	&.exiting {
		animation: maskExit 0.36s;
			-webkit-animation: maskExit 0.36s;
	}
}