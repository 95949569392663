@import "_imports";

$tranBackground: #222222;

$tranDarkPaddingTop: 35px;
$tranDarkPaddingBottom: 25px;

$tranLightPaddingTop: 12px;
$tranLightPaddingBottom: 12px;

.tran {
	z-index: 9;
	border-bottom: 1px solid $gunmetalVeryWeak;

	&.menu {
		background: rgba(#f2f4f5, 0.96);
	}

	&.dark {
		padding: $tranDarkPaddingTop 0 $tranDarkPaddingBottom;
		background: $tranBackground;

		&.underlayHeader {
			margin-top: -$headerHeight;
			padding-top: $tranDarkPaddingTop + $headerHeight;
		}

		.control {
			input,
			textarea,
			.element {
				border: none;
			}
		}
	}

	&.light {
		padding: $tranLightPaddingTop 0 $tranLightPaddingBottom;
		background: rgba(white, 0.96);
		font-weight: 400;		// .light class name clash

		&.underlayHeader {
			margin-top: -$headerHeight;
			padding-top: $tranLightPaddingTop + $headerHeight;
		}

		.span {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -$tranLightPaddingBottom;
				width: 100%;
				height: 0;
				border-bottom: 1px solid $gunmetalWeak;
			}
		}

		&.scrollfix {
			.span {
				position: static;

				&:after {
					bottom: 0;
					border-bottom-color: $gunmetalVeryWeak;
				}
			}
		}
	}

	.back {
		height: 29px;
		line-height: 29px;

		a {
			position: relative;
			display: inline-block;
			vertical-align: bottom;
			margin-bottom: 4px - $bodyDescenderHeight;
			padding-left: 19px;

			.glyph {
				position: absolute;
        left: -6px;
        top: 50%;
        line-height: inherit;
        @include title();
        @include glyph();
        @include transform(translateY(-8px));
        font-weight: 700;
			}
		}
	}

	.control.inline + .control.inline {
		margin-left: 10px;
	}
}


/*
 * Desktop
 */

@include breakpoint($desktop) {
	.tran {
		.back {
			a {
				.glyph {
					position: absolute;
					right: 100%;
				}
			}
		}
	}
}


/*
 * Mobile
 */

@include breakpoint($mobile) {

}


/*
 * See the _mixins.scss file for the tran mixin
 */
