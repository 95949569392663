
.uiPill {
	display: block;
	
	.options {
		display: table;
		width: 100%;

		.wrapper {
			display: table-row;

			li {
				display: table-cell;
				@include box-sizing(border-box);
				height: $formsElementHeight;
				line-height: $formsElementHeight;
				border-top: $formsElementBorderWidth solid $formsElementBorderColor;
				border-bottom: $formsElementBorderWidth solid $formsElementBorderColor;
				border-left: $formsElementBorderWidth solid $formsElementBorderColor;
				text-align: center;
				color: $gunmetalNeutral;
				cursor: pointer;
				@include transition(all $transitionLengthMedium);

				&:first-child {
					border-radius: $formsElementBorderRadius 0 0 $formsElementBorderRadius;
				}

				&:last-child {
					border-right: $formsElementBorderWidth solid $formsElementBorderColor;
					border-radius: 0 $formsElementBorderRadius $formsElementBorderRadius 0;
				}

				&:hover {
					background: $formsElementItemBackgroundHover;
        }

        &:active {
        	background: $formsElementItemBackgroundActive;
        }

				&.active {
					background: $formsElementBorderColor;
					color: $white;
					cursor: default;
				}

				.content {
					position: relative;
					display: inline-block;
					top: 2px;
				}
			}
		}
	}

	// Filter style
	&.filter {
		.options {
			.wrapper {
				li {
					color: $blue;
					border-color: $blue;
					border-left-width: 0;
					border-right-width: 0;

					&:first-child {
						border-left: $formsElementBorderWidth solid $blue;
					}

					&:last-child {
						border-right: $formsElementBorderWidth solid $blue;
					}

					&:hover {
						background: $blue-5;
	        }

	        &:active {
						background: $blue-4;
	        }

					&.active {
						color: $white;
						background: $blue;
					}
				}
			}
		}
	}
}