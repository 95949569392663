
$formControlSpacing: 24px;

// Basic control
.control {
	display: block;
	position: relative;
}

.control.half {
	width: 50%;
}

// Spacing between controls
.control + .control,
.control.enforceSpacing {
	margin-top: $formControlSpacing;
}

// Forces inline style on a control
.control.inline {
	display: inline-block;
	margin-top: 0;
}

// Spacing between inline controls
.control.inline + .control.inline {
	margin-left: $formControlSpacing;
}

// Groups controls together inline
.multi {
	& > .controls {
		@include display-flex;
		display: -ms-flexbox;
		width: 100%;

		& > * {
			@include flex-grow(1);
			vertical-align: top;
		}
	}
}

// Glues two or more controls together
.compound {
	display: table;

	.controls {
		display: table-row;

		& > .control {
			display: table-cell;
			vertical-align: bottom;
		}

		& > .control:first-child {
			input,
			button {
				border-right: none;
				border-radius: $formsElementBorderRadius 0 0 $formsElementBorderRadius;
			}
		}

		& > .control:last-child {
			input,
			button {
				border-left: none;
				border-radius: 0 $formsElementBorderRadius $formsElementBorderRadius 0;
			}
		}

		.mainCell {
			width: 100%;
		}
	}
}

// Button rows
form .buttons {
	height: $formsButtonHeightMedium;
	line-height: $formsButtonHeightMedium;
	margin-top: $marginMedium;
	@include clearfix();

	.uiButton,
	.uiLinkButton {
		display: inline-block;
		vertical-align: middle;
	}

	.uiButton,
	.uiLinkButton {
		& + .uiButton,
		& + .uiLinkButton {
			margin-left: $marginSmall;
			margin-top: 0;
		}
	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}
}

// Spacing between control collections
.uiCheckbox {
	& + .uiCheckbox {
		margin-top: $formsCheckboxSpacing;
	}
}

// Right-aligned labels
.control {
	.rightLabel {
		position: absolute;
		right: 0;
		top: 1px;
	}
}


/**
 * Not phone styles
 */

@include breakpoint($notPhone) {
	.multi {
		& > .controls {
			@include flex-direction(row);

			&,
			&.halves {
				& > .controls > * {
					width: 50%;
				}
			}

			&.thirds {
				& > .controls {
					& > *,
					& > .one {
						width: 33%;
					}

					& > .two {
						width: 66%;
					}
				}
			}

			&.quarters {
				& > .controls {
					& > *,
					& > .one {
						width: 25%;
					}

					& > .two {
						width: 50%,
					}

					& > .three {
						width: 75%;
					}
				}
			}

			& > * + * {
				margin-left: $formControlSpacing;
			}

			// Top margin of parent multi takes the place of
			// all child controls' top margin, including child multis.
			& .control {
				margin-top: 0 !important;
			}

			// Push down controls without labels
			& > .uiTags,
			& > .uiButton {
				padding-top: $formsLabelHeight;
				vertical-align: middle;
			}

			// Unless the noLabel class is applied
			.noLabel & {
				padding-top: 0;
			}
		}
	}
}


/**
 * Phone styles
 */

@include breakpoint($phone) {
	.multi {
		& > .controls {
			@include flex-direction(column);
		}
	}
}
