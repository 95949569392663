@import "_imports";
$headerUnit-height: 510px;
$headerUnit-height-tablet: 420px;
$headerUnit-height-mobile: 310px;
.page#about,
.page#architecture-genome,
.page#partners {

		#minimal-footer {
				text-align: center;
				p {
						margin: 40px auto;
				}
		}
		.whiteColor {
				color: #fff;
		}
		// Form errors
		.infotext {
				display: none;
				margin: 20px auto auto;
				padding: 10px $formsElementPaddingHorizontal;
				border-radius: $formsElementBorderRadius;
				max-width: 70%;
				color: $formsElementBorderColorInvalid;
				background: $red-5;
		}
		// Button margins
		.uiButton {
				margin: 30px auto;
				& .button .label {
						text-transform: none;
				}
		}
		.container {
				max-width: 1200px;
		}
		.display2 {
				line-height: 63px;
		}
		.twentyfour {
				font-size: 24px;
				margin-top: 0;
				line-height: 36px;
		}

		.fixedHeightUnit {
				height: $headerUnit-height;
				overflow: hidden;
		}
		.contentUnit {
				.attribution {
						text-align: right;
						position: absolute;
						bottom: 30px;
						right: 30px;
						& > a.caption.attr {
								margin: 5px auto auto;
								width: auto;
						}
				}
				.textUnit {
						max-width: span(12);
						margin: 45px auto 130px;
						& div.architectureImage {
								margin: 60px auto auto;
								width: 100%;
								height: auto;
								position: relative;
						}
						& p {
								max-width: span(14);
								margin: 30px auto 0;
						}
						& .image {
								margin: 60px auto auto;
								width: 100%;
								height: auto;
								&.mobileImage {
										display: none;
								}
						}
						& .uiButton {
								margin: 30px auto;
						}
						& a.block {
								margin: 30px 0 0;
						}
				}
				&.headerUnit {
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center 60%;
						color: $white;
						height: $headerUnit-height;
						padding: 0;
						position: relative;
						& .container {
								position: relative;
								top: 50%;
								transform: translateY(-50%);
								& .image {
										height: 40px;
								}
						}
				}
		}
		.span.full {
				padding: 95px 0;
				&.shaded {
						background-color: #FDFDFD;
						border-top: 1px solid $gunmetalVeryWeak;
						border-bottom: 1px solid $gunmetalVeryWeak;
						padding: 95px 0;
						margin: 0;
						width: 100%;
				}
				&.flush {
						padding: 0;
				}
		}
		.testimonials {
				margin: 50px auto 0;
				max-width: span(14);
				p.padded {
						margin: auto;
				}
		}
		.split-unit {
				.half {
						width: 50%;
						display: inline-block;
						vertical-align: top;
						& h1 {
								margin-top: 15px;
						}
						&.right-border {
								border-right: 1px solid $gunmetalVeryWeak;
								box-sizing: border-box;
						}
						&.tall {
								padding: 100px 0;
						}
				}
				&.padded {
						.half:first-child p {
								padding-right: 80px;
						}
						// .half:last-child img {
						// 	padding-left: 80px;
						// }
						&.even {
								.half:last-child p {
										padding-left: 80px;
								}
								// .half:first-child img {
								// 	padding-right: 80px;
								// }
						}
				}
		}
		.signupForm {
				@include span(7);
				margin: auto;
				float: none;
				& .formFields {
						margin-top: 30px;
						.control.half {
								width: 48%;
								margin: 0;
								&.last {
										float: right;
								}
						}
				}
		}
}
/*
Individual Pages
*/
.page#architecture-genome {
		.contentUnit .textUnit p {
				text-align: left;
		}
}
.page#partners {
		.contentUnit .textUnit p {
				text-align: center;
		}
		.partners {
				width: span(14 of 16);
				margin: auto;
				text-align: center;
				margin-bottom: 80px;
				&.secondary {
						margin-top: 80px;
						margin-bottom: 0;
				}
				div {
						// border: 1px solid red;
						width: span(4 of 12);
						display: inline-block;
						margin: gutter() gutter() 9*gutter() gutter();
						vertical-align: middle;
						img {
								width: 100%;
						}
						p {
								padding: 4*gutter() span(1 of 12)+2*gutter();
						}
				}
		}
		hr {
				opacity: 0.5;
		}
}
/* Desktop Styles */
@include susy-breakpoint($desktop, $desktopGrid) {
		.page#about,
		.page#architecture-genome,
		.page#partners {
				.split-unit .half h1 {
						text-align: left;
				}
				.container {
						margin: auto;
						h1 {
								text-align: center;
						}
				}
		}
}
/* Tablet Styles */
@include susy-breakpoint($tablet, $desktopGrid) {
		.page#about {
				.contentUnit {
						.textUnit {
								max-width: span(14);
						}
						&.headerUnit {
								height: $headerUnit-height-tablet;
						}
				}
		}
		.page#partners {
				.contentUnit {
						.textUnit {
								max-width: span(full);
								margin-left: auto;
								margin-right: auto;
						}
				}
				.partners {
						width: span(full);
						div {
								width: span(4 of 12);
						}
				}
		}
}

/**
 * FIXME: Move $largePhone and $smallPhone into $phone
 *
 */


@include susy-breakpoint($phone, $mobileGrid) {
	.page#partners {
		.contentUnit {
				.textUnit {
						max-width: span(full);
						margin-left: auto;
						margin-right: auto;
				}
		}
		.partners {
				width: span(8 of 8);
				margin-bottom: 20px;
				&.secondary {
						margin-top: 40px;
				}
				div {
						width: span(7 of 8);
						margin-bottom: 40px;
						img {
							max-width: 260px;
						}
						p {
								width: span(7 of 8);
								margin: auto;
						}
				}
		}
	}
}
@include susy-breakpoint($largePhone, $mobileGrid) {
		.page#about,
		.page#architecture-genome {
				.split-unit {
						.half {
								width: span(8);
								margin-bottom: 30px;
						}
				}
				.signupForm {
						@include span(7);
						margin: auto;
						float: none;
				}
		}
		.page#about {
				.contentUnit {
						.textUnit {
								max-width: span(8);
								& p {
										max-width: span(8);
								}
						}
						&.headerUnit {
								height: $headerUnit-height-tablet;
						}
				}
		}
}
/* Phone Styles */
@include susy-breakpoint($smallPhone, $mobileGrid) {
		.page#about,
		.page#architecture-genome, {
				.split-unit {
						.half {
								width: span(8);
								margin-bottom: 30px;
						}
				}
				.signupForm {
						@include span(8);
				}
		}
		.page#about {
				.contentUnit {
						.textUnit {
								max-width: span(8);
								text-align: center;
								& .image {
										&.desktopImage {
												display: none;
										}
										&.mobileImage {
												display: block;
												&.inset {
														width: span(6);
												}
										}
								}
						}
						&.headerUnit {
								height: $headerUnit-height-mobile;
								& .container {
										position: relative;
										top: 50%;
										transform: translateY(-50%);
										text-align: center;
								}
						}
				}
				.span.full.shaded {
						text-align: center;
				}
				.testimonials .image {
						margin: auto auto 30px;
				}
		}
}
