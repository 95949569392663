@import "_imports";

#errorPage {
	.banner {
		position: relative;
		width: 100%;
		height: 100vh;
		color: $black;
		overflow: hidden;
		text-align: center;

		h3 {
			font-size: 52px;
			font-weight: 300;

			&.large {
				font-size: 120px;
			}
		}

		h3 + p {
			margin-top: 70px
		}

		p {
			width: span(8 of $desktopColumns);
			margin-left: auto;
			margin-right: auto;
			font-size: 16px;
			font-weight: 300;
			line-height: 22px;

			&.large {
				font-size: 24px;
				line-height: 34px;
			}
		}
		.content {
			@include box-sizing(border-box);
			position: relative;
			@include display-flex;
				display: -ms-flexbox;
			@include flex-direction(column);
				-ms-flex-direction: column;
			width: 100%;
			height: 100vh;
			z-index: 3;
			padding: $headerHeight 0 0 !important;

			& > .main {
				width: 100%;
				@include display-flex;
					display: -ms-flexbox;
				@include align-items(center);
					-ms-flex-align: center;
				@include flex-grow(1);
					-ms-flex: 1 1 auto;

				.middle {
					width: 100%;
				}
			}
		}
	}
}
