@import "_imports";

$hero-height: 480px;

#careers {

	.display1 {
		margin-bottom: 100px - $display1DescenderHeight;
	}

	.department {
		& + .department {
			position: relative;
			padding-top: 47px;
			margin-top: 47px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				border-top: 1px solid rgba($gunmetalWeak, 0.5);
			}
		}
	}

	.job {
		& + .job {
			margin-top: 32px - $titleArtifactHeight;
		}
	}
}


/**
 * Responsive page dividers
 */

@include susy-breakpoint($external, $desktopGrid) {
	#careers {
		.department {
			& + .department {
				&:before {
					left: span(3) + 3*gutter();
					width: span(10);
				}
			}
		}
	}
}

@include susy-breakpoint($laptop, $desktopGrid) {
	#careers {
		.department {
			& + .department {
				&:before {
					left: span(2) + 3*gutter();
					width: span(12);
				}
			}
		}
	}
}

@include susy-breakpoint($mobile, $mobileGrid) {
	#careers {
		.department {
			& + .department {
				&:before {
					left: gutter();
					width: span(full);
				}
			}
		}
	}
}

@include susy-breakpoint($external, $desktopGrid) {

	#careers {
		.department {
			text-align: left;

			.headline {
				@include span(4);
				margin-left: span(3) + 3*gutter();
			}

			.jobs {
				@include span(6);
				margin-top: $headlineBaselineHeight - $titleBaselineHeight;
			}
		}
	}
}

@include susy-breakpoint($laptop, $desktopGrid) {

	#careers {
		.department {
			text-align: left;

			.headline {
				@include span(5);
				margin-left: span(2) + 3*gutter();
			}

			.jobs {
				@include span(7);
				margin-top: $headlineBaselineHeight - $titleBaselineHeight;
			}
		}
	}
}

@include susy-breakpoint($tablet, $mobileGrid) {

	#careers {
		.department {
			text-align: left;

			.headline {
				@include span(3);
			}

			.jobs {
				@include span(5 last);
				margin-top: $headlineBaselineHeight - $titleBaselineHeight;
			}
		}
	}
}

@include susy-breakpoint($phone, $mobileGrid) {

	#careers {
		.department {
			.headline {
				@include span(full);
			}

			.jobs {
				@include span(full);
				margin-top: 42px - $titleArtifactHeight;
			}
		}
	}
}
