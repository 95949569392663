
.uiScrollMask {
	position: relative;

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 10px;
		z-index: 900;
		opacity: 0;
		display: none;
	}

	/* Vertical scrolling */
	&.directionY {
		
		&:before {
			top: 0;
			border-top: 1px solid $white;
			@include background-image(linear-gradient($white 0%, rgba($white, 0.8) 20%, rgba($white, 0.50196) 50%, rgba($white, 0.2) 80%, rgba($white, 0) 100%));
		}

		&:after {
			bottom: 0;
			border-bottom: 1px solid white;
			@include background-image(linear-gradient(rgba($white, 0) 0%, rgba($white, 0.2) 20%, rgba($white, 0.50196) 50%, rgba($white, 0.8) 80%, $white 100%));
		}
	}


	/* Top feathering mask animation */
	&.contentBefore-add,
	&.contentBefore-remove {
		&,
		&:before {
			@include transition(opacity $transitionLengthShort);
		}
	}

	&.contentBefore-add {
		&:before {
			display: block;
			opacity: 0;
		}
	}

	&.contentBefore,
	&.contentBefore-add-active {
		&:before {
			display: block;
			opacity: 1;
		}
	}

	&.contentBefore-remove {
		&:before {
			display: block;
			opacity: 1;
		}
	}

	&.contentBefore-remove-active {
		&:before {
			opacity: 0;
		}
	}


	/* Bottom feathering mask animation */
	&.contentAfter-add,
	&.contentAfter-remove {
		&,
		&:after {
			@include transition(opacity $transitionLengthShort);
		}
	}

	&.contentAfter-add {
		&:after {
			display: block;
			opacity: 0;
		}
	}

	&.contentAfter,
	&.contentAfter-add-active {
		&:after {
			display: block;
			opacity: 1;
		}
	}

	&.contentAfter-remove {
		&:after {
			display: block;
			opacity: 1;
		}
	}

	&.contentAfter-remove-active {
		&:after {
			opacity: 0;
		}
	}
}
