
$formsAutocompleteAvatarSize: 46px;
$formsAutocompleteAvatarMargin: 10px;

.uiSelect {
	&.uiAutocomplete {
		& > .element {
			&:before {
				content: none;
			}

			.element {
				padding: 0;
				border: 0;
				background: none;

				input {
					background: none;
				}
			}
		}
	}
}

.uiAutocomplete {
	& > .element {
		.uiTags {
			position: absolute;
			left: ($formsElementHeight - $formsTagHeight - 2*$formsElementBorderWidth)/2 - $formsTagMargin;
			top: ($formsElementHeight - $formsTagHeight - 2*$formsElementBorderWidth)/2;
			margin-top: 0;
		}

		.uiText {
			@include flex-grow(1);

			.uiErrors {
				display: none !important;
			}
		}
	}

	&.focus {
		border-color: $formsElementBorderColorActive;
	}
}

.uiAutocompleteDropdown {
	.option {
		min-height: 50px;
		height: auto;
		@include box-sizing(border-box);
		@include clearfix();
		@include display-flex;
			display: -ms-flexbox;
		@include align-items(center);

		&:hover {
			background: rgba($gunmetalWeak, 0.1);
			cursor: pointer;
		}

		& > .image {
			display: none;
			width: $formsAutocompleteAvatarSize;
			height: $formsAutocompleteAvatarSize;
			margin-right: $formsAutocompleteAvatarMargin;
			background-size: cover;
		}

		.text {
			width: 100%;
			line-height: normal;

			.primary,
			.secondary {
				display: block;
			}

			.primary {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&.user {
			.image {
				border-radius: $formsAutocompleteAvatarSize/2;
			}
		}
	}

	&.showImages {
		.options {
			.option {
				position: relative;
				height: $formsAutocompleteAvatarSize;
				line-height: $formsAutocompleteAvatarSize;
				padding-left: $formsAutocompleteAvatarSize + $formsAutocompleteAvatarMargin + $formsElementPaddingHorizontal;

				& > .image {
					display: block;
					position: absolute;
					left: $formsElementItemPadding;
					top: $formsElementItemPadding;
				}

				.text {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}
