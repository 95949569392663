
.uiLinkButton {
	position: relative;
	display: inline-block;

	button {
		padding: 0;
		margin: 0;
		border: none;
		font-family: inherit;
		font-size: inherit;
		background: none;
		cursor: pointer;
		white-space: nowrap;
		@include transition(color $transitionLengthShort);
		
		&:active,
		&:focus {
			outline: none;
		}
	
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: $linkUnderlineThickness;
			left: 0;
			bottom: 1px;
			@include transition(all $transitionLengthShort);
		}
		
		&[disabled] {
			color: $gunmetalWeak !important;
			cursor: default;
			pointer-events: none;
			
			&:after {
				background: $gunmetalWeak !important; 
			}
		}
	}

	label + & {
		margin-top: $marginSmall;
	}
	
		

	// Colors
	&.default {
		button {
			color: $gunmetalNeutral;
	
			&:after {
				background: $gunmetalNeutral;
			}
	
			&:hover {
				color: #91999e;
	
				&:after {
					background: #91999e;
				}
			}
	
			&:active {
				color: #6c7275;
	
				&:after {
					background: #6c7275;
				}
			}
		}
	}

	&.primary {
		button {
			color: $blue;
	
			&:after {
				background: $blue;
			}
	
			&:hover {
				color: $blue1;
	
				&:after {
					background: $blue1;
				}
			}
	
			&:active {
				color: $blue2;
	
				&:after {
					background: $blue2;
				}
			}
		}
			
	}

	&.success {
		button {
			color: $green;
	
			&:after {
				background: $green;
			}
	
			&:hover {
				color: $green1;
	
				&:after {
					background: $green1;
				}
			}
	
			&:active {
				color: $green2;
	
				&:after {
					background: $green2;
				}
			}
		}
	}

	&.danger {
		button {
			color: $red;
	
			&:after {
				background: $red;
			}
	
			&:hover {
				color: $red1;
	
				&:after {
					background: $red1;
				}
			}
	
			&:active {
				color: $red2;
	
				&:after {
					background: $red2;
				}
			}
		}
	}	
}

// Sizes
.body {
	&.uiLinkButton,
	.uiLinkButton {
		height: $bodyLineHeight - 1px;
	}
}

.subhead {
	&.uiLinkButton,
	.uiLinkButton {
		height: $subheadLineHeight - 1px;
	}
}