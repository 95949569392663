
$attributionColor: $strong;
$attributionImageSizeSmall: 27px;
$attributionImageSizeMedium: 30px;

.uiAttribution {
	@include clearfix();

	.image {
		float: left;
		margin: -1px;
		border: 1px solid transparent;

		&:hover {
			border-color: $blue;
		}

		img {
			display: block;
		}
	}

	.primary,
	.secondary {
		display: block;
		white-space: nowrap;
	}

	.secondary {
		a, .by {
			color: $weak;
		}
		
		a {
			display: inline-block;
		}
	}

	// Emphasis
	.secondary.emphasise {
		a, .by {
			color: $attributionColor;
		}
	}

	.secondary,
	.secondary.emphasise {
		a:hover {
			color: $blue;
		}
	}

	&.image {
		.secondary.emphasise {
			a, .by {
				color: $white;
			}
		}

		.secondary,
		.secondary.emphasise {
			a:hover {
				color: $blue;
			}
		}
	}

	// Sizes
	&.small {
		.image {
			margin-right: $marginSmall;

			img {
				width: $attributionImageSizeSmall;
				height: $attributionImageSizeSmall;
			}
		}

		.secondary {
			margin-top: $attributionImageSizeSmall - ($bodyBaselineHeight - $bodyArtifactHeight) - $captionBaselineHeight;
		}

		&.singleLine {
			.primary {
				margin-top: ($attributionImageSizeSmall - $captionBaselineHeight)/2 - $captionArtifactHeight;
			}
		}
	}

	&.medium {
		.image {
			margin-right: $marginSmall;

			img {
				width: $attributionImageSizeMedium;
				height: $attributionImageSizeMedium;
			}
		}

		.secondary {
			margin-top: $attributionImageSizeMedium - ($subheadBaselineHeight - $subheadArtifactHeight) - $bodyBaselineHeight;
		}

		&.singleLine {
			.primary {
				margin-top: ($attributionImageSizeMedium - $bodyBaselineHeight)/2 - $bodyArtifactHeight;
			}
		}
	}

	&.large {
		.secondary {
			margin-top: $marginMedium;
		}
	}

	// Backgrounds
	&.image {
		.primary {
			a {
				color: $white;

				&:hover {
					color: $blue;
				}
			}		
		}

		.secondary {
			a, .by {
				color: $whiteNeutral;
			}

			a {
				&:hover {
					color: $blue;
				}
			}
		}
	}
}